import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE, TimelineType } from "../components/Shared/constants";
import modernWorkSecurityEventCalendarMomentService from "../services/modernWorkSecurityEventCalendarMoment";
import { ErrorMessage } from "../components/Shared/messageBox";
import moment from "moment";

interface modernWorkSecurityEventCalendarMomentState {
  modernWorkSecurityEventCalendarMoment: modernWorkSecurityEventCalendarMoment | null;
  secondModernWorkSecurityEventCalendarMoment: modernWorkSecurityEventCalendarMoment | null;
  isMomentListLoading: string;
  modernWorkSecurityEventCalendarMomentList: modernWorkSecurityEventCalendarMoment[];
  isMomentListForDetailLoading: string;
  modernWorkSecurityEventCalendarMomentListForDetail: modernWorkSecurityEventCalendarMoment[];
}

const initialState: modernWorkSecurityEventCalendarMomentState = {
  modernWorkSecurityEventCalendarMoment: null,
  secondModernWorkSecurityEventCalendarMoment: null,
  isMomentListLoading: "",
  modernWorkSecurityEventCalendarMomentList: [],
  isMomentListForDetailLoading: "",
  modernWorkSecurityEventCalendarMomentListForDetail: [],
};

export interface modernWorkSecurityEventCalendarMoment {
  Id: number;
  Title: string;
  StartDate: string;
  EndDate: string;
  ProductFamily: string;
  MomentType: string;
  MomentTier: string;
  Location: string;
  BICContact: string;
  CategoryName: string;
  Description: string;
  CMRDID: number | null;
  DisplayText: string;
  DisplayTextWithYear: string;
  DisplayTextWithYearAndTBD: string;
  BIC_DateSelection: string;
  MultipleCSA: string;
  TargetAudience: string;
  MainDisclosureChannel: string;
  NewsZoneContent: string;
  NewsZone: string;
}

export interface modernWorkSecurityEventCalendarMomentOption {
  Id: number;
  CMRDID: number | null;
  StartDate: string;
  EndDate: string;
  Title: string;
  DisplayText: string;
  DisplayTextWithYear: string;
  DisplayTextWithYearAndTBD: string;
}

export const getModernWorkSecurityEventCalendarMomentById = createAsyncThunk(
  "getModernWorkSecurityEventCalendarMomentById",
  async (id: number) => {
    return await modernWorkSecurityEventCalendarMomentService.getModernWorkSecurityEventCalendarMomentById(
      id
    );
  }
);

export const getSecondModernWorkSecurityEventCalendarMomentById =
  createAsyncThunk(
    "getSecondModernWorkSecurityEventCalendarMomentById",
    async (id: number) => {
      return await modernWorkSecurityEventCalendarMomentService.getModernWorkSecurityEventCalendarMomentById(
        id
      );
    }
  );

export const getModernWorkSecurityEventCalendarMomentList = createAsyncThunk(
  "getModernWorkSecurityEventCalendarMomentList",
  async (args: { startDate: string; endDate: string }, dispatch) => {
    return await modernWorkSecurityEventCalendarMomentService.getModernWorkSecurityEventCalendarMomentList(
      args.startDate,
      args.endDate
    );
  }
);

export const getModernWorkSecurityEventCalendarMomentListByTimelineType =
  createAsyncThunk(
    "getModernWorkSecurityEventCalendarMomentListByTimelineType",
    async (args: { timelineType: TimelineType; date?: Date }) => {
      return await modernWorkSecurityEventCalendarMomentService.getModernWorkSecurityEventCalendarMomentListByTimelineType(
        args.timelineType,
        args.date ? moment(args.date).format("MM/DD/YYYY") : ""
      );
    }
  );

export const getModernWorkSecurityEventCalendarMomentListForDetail =
  createAsyncThunk(
    "getModernWorkSecurityEventCalendarMomentListForDetail",
    async (args: { timelineType: TimelineType; date?: Date }) => {
      return await modernWorkSecurityEventCalendarMomentService.getModernWorkSecurityEventCalendarMomentListByTimelineType(
        args.timelineType,
        args.date ? moment(args.date).format("MM/DD/YYYY") : ""
      );
    }
  );

const modernWorkSecurityEventCalendarMomentSlice = createSlice({
  name: "modernWorkSecurityEventCalendarMoment",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getModernWorkSecurityEventCalendarMomentById.fulfilled.type]: (
      state: modernWorkSecurityEventCalendarMomentState,
      action: PayloadAction<modernWorkSecurityEventCalendarMoment | null>
    ) => {
      state.modernWorkSecurityEventCalendarMoment = action.payload;
    },
    [getModernWorkSecurityEventCalendarMomentById.rejected.type]: (
      state,
      { error }
    ) => {
      ErrorMessage.show(
        "There was an error getting Modern Work/Security event calendar moment by ID. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getSecondModernWorkSecurityEventCalendarMomentById.fulfilled.type]: (
      state: modernWorkSecurityEventCalendarMomentState,
      action: PayloadAction<modernWorkSecurityEventCalendarMoment | null>
    ) => {
      state.secondModernWorkSecurityEventCalendarMoment = action.payload;
    },
    [getSecondModernWorkSecurityEventCalendarMomentById.rejected.type]: (
      state,
      { error }
    ) => {
      ErrorMessage.show(
        "There was an error getting Modern Work/Security event calendar moment by ID. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getModernWorkSecurityEventCalendarMomentList.pending.type]: (
      state: modernWorkSecurityEventCalendarMomentState
    ) => {
      state.isMomentListLoading = _LOADINGSTATE.pending;
    },
    [getModernWorkSecurityEventCalendarMomentList.fulfilled.type]: (
      state: modernWorkSecurityEventCalendarMomentState,
      action: PayloadAction<modernWorkSecurityEventCalendarMoment[]>
    ) => {
      state.modernWorkSecurityEventCalendarMomentList = action.payload;
      state.isMomentListLoading = _LOADINGSTATE.fullfilled;
    },
    [getModernWorkSecurityEventCalendarMomentList.rejected.type]: (
      state,
      { error }
    ) => {
      ErrorMessage.show(
        "There was an error getting Modern Work/Security event calendar moment list. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getModernWorkSecurityEventCalendarMomentListByTimelineType.pending.type]: (
      state: modernWorkSecurityEventCalendarMomentState
    ) => {
      state.isMomentListLoading = _LOADINGSTATE.pending;
    },
    [getModernWorkSecurityEventCalendarMomentListByTimelineType.fulfilled.type]:
      (
        state: modernWorkSecurityEventCalendarMomentState,
        action: PayloadAction<modernWorkSecurityEventCalendarMoment[]>
      ) => {
        state.modernWorkSecurityEventCalendarMomentList = action.payload;
        state.isMomentListLoading = _LOADINGSTATE.fullfilled;
      },
    [getModernWorkSecurityEventCalendarMomentListByTimelineType.rejected.type]:
      (state, { error }) => {
        ErrorMessage.show(
          "There was an error getting Modern Work/Security event calendar moment list. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      },
    [getModernWorkSecurityEventCalendarMomentListForDetail.pending.type]: (
      state: modernWorkSecurityEventCalendarMomentState
    ) => {
      state.isMomentListForDetailLoading = _LOADINGSTATE.pending;
    },
    [getModernWorkSecurityEventCalendarMomentListForDetail.fulfilled.type]: (
      state: modernWorkSecurityEventCalendarMomentState,
      action: PayloadAction<modernWorkSecurityEventCalendarMoment[]>
    ) => {
      state.modernWorkSecurityEventCalendarMomentListForDetail = action.payload;
      state.isMomentListForDetailLoading = _LOADINGSTATE.fullfilled;
    },
    [getModernWorkSecurityEventCalendarMomentListForDetail.rejected.type]: (
      state,
      { error }
    ) => {
      ErrorMessage.show(
        "There was an error getting Modern Work/Security event calendar moment list for detail. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { actions, reducer } = modernWorkSecurityEventCalendarMomentSlice;
