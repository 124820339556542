import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import customEventDateService from "../services/customEventDate";
import { ErrorMessage } from "../components/Shared/messageBox";

interface customEventDateState {
  isCustomEventDatesLoading: string;
  customEventDates: customEventDate[];
  customEventDate: customEventDate | null;
  customEventDateForCalendar: customEventDateForCalendarProps[];
}

const initialState: customEventDateState = {
  isCustomEventDatesLoading: _LOADINGSTATE.pending,
  customEventDates: [],
  customEventDate: null,
  customEventDateForCalendar: [],
};

export interface customEventDate {
  CustomEventDateId: number;
  DisplayText: string;
  StartDate: string;
  EndDate: string;
  Name: string;
  Location: string;
  Contact: string;
  AudienceFocusText: string;
  PartyTypeText: string;
  FormatTypeText: string;
  Description: string;
  MoreInfo: string;
}

export interface customEventDateForCalendarProps {
  Date: string;
  Name: string;
}

export const getCustomEventDates = createAsyncThunk(
  "getCustomEventDates",
  async () => await customEventDateService.getCustomEventDates()
);

export const getCustomEventDateById = createAsyncThunk(
  "getCustomEventDateById",
  async (id: number) => {
    return await customEventDateService.getCustomEventDateById(id);
  }
);

export const getCustomEventDateByMilestoneId = createAsyncThunk(
  "getCustomEventDateByMilestoneId",
  async (milestoneId: number) => {
    return await customEventDateService.getCustomEventDateByMilestoneId(
      milestoneId
    );
  }
);

export const getCustomEventDatesForCalendar = createAsyncThunk(
  "getCustomEventDatesForCalendar",
  async () => await customEventDateService.getCustomEventDateForCalendar()
);

const customEventDateSlice = createSlice({
  name: "customEvent",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getCustomEventDates.fulfilled.type]: (
      state: customEventDateState,
      action: PayloadAction<customEventDate[] | string>
    ) => {
      if (typeof action.payload === "string") {
        state.customEventDates = [];
      } else {
        state.customEventDates = action.payload;
      }
      state.isCustomEventDatesLoading = _LOADINGSTATE.fullfilled;
    },
    [getCustomEventDates.pending.type]: (state: customEventDateState) => {
      state.isCustomEventDatesLoading = _LOADINGSTATE.pending;
    },
    [getCustomEventDates.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error in getting the custom event dates. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getCustomEventDateById.fulfilled.type]: (
      state: customEventDateState,
      action: PayloadAction<customEventDate | null>
    ) => {
      state.customEventDate = action.payload;
    },
    [getCustomEventDateById.pending.type]: (state: customEventDateState) => {},
    [getCustomEventDateById.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error in getting the custom event dates by ID. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getCustomEventDateByMilestoneId.fulfilled.type]: (
      state: customEventDateState,
      action: PayloadAction<customEventDate | null>
    ) => {
      state.customEventDate = action.payload;
    },
    [getCustomEventDateByMilestoneId.pending.type]: (
      state: customEventDateState
    ) => {},
    [getCustomEventDateById.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error in getting the custom event dates by milestone ID. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getCustomEventDatesForCalendar.fulfilled.type]: (
      state: customEventDateState,
      action: PayloadAction<customEventDateForCalendarProps[]>
    ) => {
      state.customEventDateForCalendar = action.payload;
    },
    [getCustomEventDatesForCalendar.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error in getting the custom event dates for calendar. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { actions, reducer } = customEventDateSlice;
