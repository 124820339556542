import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  convertMonthStringToDate,
  getFirstDayInTheMonth,
  getLastDayInTheMonth,
} from "../utils/date";
import { isVoid } from "../utils/utils";
import { TimelineType, _LOADINGSTATE } from "../components/Shared/constants";
import timelineService from "../services/timeline";
import modernWorkFeatureService from "../services/modernWorkFeature";
import modernWorkSecurityEventCalendarMomentService from "../services/modernWorkSecurityEventCalendarMoment";
import modernWorkTimelineService from "../services/modernWorkTimeline";
import { ModernWorkFeature } from "./modernWorkFeature";
import { MomentsGroupByProps } from "./timeline";
import { milestoneTier } from "./milestoneTier";
import { ErrorMessage } from "../components/Shared/messageBox";
import { modernWorkSecurityEventCalendarMomentOption } from "./modernWorkSecurityEventCalendarMoment";

export interface Option {
  Id: number;
  Text: string;
}

export interface ModernWorkFeatureProductAreaGroupedOption {
  label: string;
  options: ModernWorkFeatureProductAreaOption[];
}

export interface ModernWorkFeatureProductAreaOption {
  label: string;
  value: string;
}

export interface ModernWorkFeatureFilter {
  MilestoneTier: milestoneTier[];
  Moments: modernWorkSecurityEventCalendarMomentOption[];
  Product: Option[];
  PublicRoadmapStatus: Option[];
  Status: Option[];
  CloudInstance: Option[];
  CustomerRing: Option[];
  Platform: Option[];
  TriageTag: Option[];
  ProductAreas: ModernWorkFeatureProductAreaGroupedOption[];
}

const ModernWorkFeatureFilterInit: ModernWorkFeatureFilter = {
  MilestoneTier: [],
  Moments: [],
  Product: [],
  PublicRoadmapStatus: [],
  Status: [],
  CloudInstance: [],
  CustomerRing: [],
  Platform: [],
  TriageTag: [],
  ProductAreas: [],
};

export interface modernWorkTimelineFeature {
  Id: number;
  Title: string;
  PublicTitle: string;
  MarketingTitle: string;
  SourceADOID: string;
  RoadmapId: string;
  Product: string;
  PreviewDate: string;
  GADate: string;
  LaunchDate: string;
  DatePublishedToPublicRoadmap: string;
  LastModifiedOnPublicRoadmap: string;
  DisclosureDate?: string;
  LastDisclosureDate?: string;
  PublicDisclosureDate?: string;
  PlannedProductionDate?: string;
  Modified: string;
  Created: string;
  PMContact: string;
  PMGContact: string;
  ReleaseFork: string;
  PublicRoadmapStatus: string;
  Status: string;
  CustomerRings: string;
  CloudInstance: string;
  Platform: string;
  TriageTagId: number;
  TriageTagIds: number[];
  TriageTagName: string;
  TierId?: number;
  Tier: string;
  TriageDescription: string;
  MSETStags: string;
  ModernWorkSecurityEventCalendarMomentsId?: number;
  ModernWorkSecurityEventCalendarMomentsTitle: string;
  SecondModernWorkSecurityEventCalendarMomentsId?: number;
  SecondModernWorkSecurityEventCalendarMomentsTitle: string;
  ModernWorkFeatureProductAreaId: number | null;
  CCHFeatureId: number;
}

export const initialModernWorkTimelineFeatureState: modernWorkTimelineFeature =
  {
    Id: 0,
    Title: "",
    PublicTitle: "",
    MarketingTitle: "",
    SourceADOID: "",
    RoadmapId: "",
    Product: "",
    PreviewDate: "",
    GADate: "",
    LaunchDate: "",
    DatePublishedToPublicRoadmap: "",
    LastModifiedOnPublicRoadmap: "",
    DisclosureDate: undefined,
    LastDisclosureDate: undefined,
    PublicDisclosureDate: undefined,
    PlannedProductionDate: undefined,
    Modified: "",
    Created: "",
    PMContact: "",
    PMGContact: "",
    ReleaseFork: "",
    PublicRoadmapStatus: "",
    Status: "",
    CustomerRings: "",
    CloudInstance: "",
    Platform: "",
    TriageTagId: 0,
    TriageTagIds: [],
    TriageTagName: "",
    TierId: 0,
    Tier: "",
    TriageDescription: "",
    MSETStags: "",
    ModernWorkSecurityEventCalendarMomentsId: 0,
    ModernWorkSecurityEventCalendarMomentsTitle: "",
    SecondModernWorkSecurityEventCalendarMomentsId: 0,
    SecondModernWorkSecurityEventCalendarMomentsTitle: "",
    ModernWorkFeatureProductAreaId: null,
    CCHFeatureId: 0,
  };

export interface featureGroupByProps {
  month: string;
  features: {
    productName: string;
    feature: modernWorkTimelineFeature[];
    featureExcelDownload: ModernWorkFeature[];
  }[];
}

export interface DataSetProps {
  month: string;
  moments: MomentsGroupByProps;
  feature: featureGroupByProps;
  isLoading: string;
}

export const initDataSetState: DataSetProps[] = [];

export interface initialStateProps {
  dataSet: DataSetProps[];
  filterDataSet: DataSetProps[];
  featureExcelDownload: {
    productName: string;
    featureExcelDownload: ModernWorkFeature[];
  }[];
  isMonthLoading: string;
  isLoadingSearchResult: boolean;
  isLoadingExportExcelFeature: boolean;
  isLoadedExportExcelFeature: boolean;
  isExportingExcelFeature: boolean;
  modernWorkTimelineFilter: ModernWorkFeatureFilter;
  isFilterLoading: string;
}

const initialState: initialStateProps = {
  dataSet: initDataSetState,
  filterDataSet: initDataSetState,
  featureExcelDownload: [],
  isMonthLoading: _LOADINGSTATE.pending,
  isLoadingSearchResult: false,
  isLoadingExportExcelFeature: false,
  isLoadedExportExcelFeature: false,
  isExportingExcelFeature: false,
  modernWorkTimelineFilter: ModernWorkFeatureFilterInit,
  isFilterLoading: _LOADINGSTATE.pending,
};

export const initModernWorkTimelineDataSet = createAsyncThunk(
  "initModernWorkTimelineDataSet",
  async (timelineType: TimelineType) =>
    (await Promise.all([
      modernWorkFeatureService.getMSETSFeatureMonths(timelineType.toString()),
      modernWorkSecurityEventCalendarMomentService.getModernWorkSecurityEventCalendarMomentMonths(
        timelineType.toString()
      ),
    ]).then((featureResponse) => {
      const featureMonths = featureResponse[0] as string[];
      const modernWorkSecurityEventCalendarMomentMonths =
        featureResponse[1] as string[];
      const months = Array.from(
        new Set(
          featureMonths.concat(modernWorkSecurityEventCalendarMomentMonths)
        )
      );
      months.sort();
      return months;
    })) as string[]
);

export const getDataSet = (
  startDate: Date,
  endDate: Date,
  timelineType: TimelineType
) => {
  return async (dispatch: any) => {
    dispatch(
      modernWorkTimelineSlice.actions.setDataSetIsLoading({
        startDate: getFirstDayInTheMonth(
          moment(startDate).format("MM/DD/YYYY")
        ),
        endDate: getLastDayInTheMonth(moment(endDate).format("MM/DD/YYYY")),
      })
    );

    await Promise.all([
      timelineService.getMoments(
        moment(startDate).format("MM/DD/YYYY"),
        moment(endDate).format("MM/DD/YYYY"),
        timelineType.toString()
      ),
      modernWorkTimelineService.getTimelineMSETSFeature(
        moment(startDate).format("MM/DD/YYYY"),
        moment(endDate).format("MM/DD/YYYY")
      ),
    ])
      .then((response) => {
        const momenstListPayload = response[0] as MomentsGroupByProps[];
        const featureListPayload = response[1] as featureGroupByProps[];
        dispatch(
          modernWorkTimelineSlice.actions.setDataSet({
            moment: momenstListPayload,
            feature: featureListPayload,
          })
        );
        dispatch(
          modernWorkTimelineSlice.actions.setDataSetIsLoaded({
            startDate: getFirstDayInTheMonth(
              moment(startDate).format("MM/DD/YYYY")
            ),
            endDate: getLastDayInTheMonth(moment(endDate).format("MM/DD/YYYY")),
          })
        );
      })
      .catch((error: any) => {
        ErrorMessage.show(
          "There was an error getting features. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      });
  };
};

export const getFeatureDataSet = (startDate?: Date, endDate?: Date) => {
  return async (dispatch: any) => {
    modernWorkTimelineService
      .getTimelineMSETSFeature(
        startDate === undefined ? "" : moment(startDate).format("MM/DD/YYYY"),
        endDate === undefined ? "" : moment(endDate).format("MM/DD/YYYY")
      )
      .then((response) => {
        const featureListPayload = response as featureGroupByProps[];
        dispatch(
          modernWorkTimelineSlice.actions.setFeatureDataSet({
            feature: featureListPayload,
          })
        );
      })
      .catch((error: any) => {
        ErrorMessage.show(
          "There was an error getting features. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      });
  };
};

export const getExcelDownloadFeatureDataSet = (
  startDate?: Date,
  endDate?: Date
) => {
  return async (dispatch: any) => {
    const excelDownloadFeatureListPayload = (await modernWorkTimelineService
      .getTimelineExcelDownloadMSETSFeature(
        startDate === undefined ? "" : moment(startDate).format("MM/DD/YYYY"),
        endDate === undefined ? "" : moment(endDate).format("MM/DD/YYYY")
      )
      .catch((error: any) => {
        ErrorMessage.show(
          "There was an error download features. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      })) as {
      productName: string;
      featureExcelDownload: ModernWorkFeature[];
    }[];

    dispatch(
      modernWorkTimelineSlice.actions.setExcelDownloadFeatureDataSet({
        feature: excelDownloadFeatureListPayload,
      })
    );
  };
};

export const getModernWorkTimelineFilters = createAsyncThunk(
  "getModernWorkTimelineFilters",
  async (timelineType: TimelineType) =>
    await modernWorkTimelineService.getFilters(timelineType.toString())
);

export const getSearchDataSet = (searchPhrase?: string) => {
  return async (dispatch: any) => {
    dispatch(modernWorkTimelineSlice.actions.setIsLoadingSearchResult(true));
    if (
      searchPhrase !== undefined &&
      searchPhrase !== null &&
      searchPhrase.trim() !== ""
    ) {
      modernWorkTimelineService
        .getTimelineMSETSFeatureSearch(searchPhrase)
        .then((response) => {
          const featureListPayload = response as featureGroupByProps[];
          if (featureListPayload.length > 0) {
            const months = featureListPayload.map((x) => x.month);
            dispatch(
              modernWorkTimelineSlice.actions.setMonth({ dateList: months })
            );
            dispatch(
              modernWorkTimelineSlice.actions.setDataSet({
                moment: [],
                feature: featureListPayload,
              })
            );

            let date = featureListPayload[0].month;

            dispatch(
              modernWorkTimelineSlice.actions.setDataSetIsLoaded({
                startDate: moment(date).toDate(),
                endDate: moment(date).toDate(),
              })
            );
          } else {
            dispatch(
              modernWorkTimelineSlice.actions.setMonth({ dateList: [] })
            );
            dispatch(
              modernWorkTimelineSlice.actions.setDataSet({
                moment: [],
                feature: [],
              })
            );
          }
          dispatch(
            modernWorkTimelineSlice.actions.setIsLoadingSearchResult(false)
          );
        });
    }
  };
};

export const getExcelDownloadSearchFeatureDataSet = (searchPhrase?: string) => {
  return async (dispatch: any) => {
    const excelDownloadFeatureListPayload =
      (await modernWorkTimelineService.getTimelineExcelDownloadSearchMSETSFeature(
        searchPhrase
      )) as {
        productName: string;
        featureExcelDownload: ModernWorkFeature[];
      }[];

    dispatch(
      modernWorkTimelineSlice.actions.setExcelDownloadFeatureDataSet({
        feature: excelDownloadFeatureListPayload,
      })
    );
  };
};

const modernWorkTimelineSlice = createSlice({
  name: "modernWorkTimeline",
  initialState: initialState,
  reducers: {
    setMonth: (
      state: initialStateProps,
      action: {
        payload: { dateList: string[] };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = [];
      action.payload.dateList.map((item, index) => {
        const data = {
          month: item,
          moments: { month: item, moment: [] },
          feature: { month: item, features: [] },
          isLoading: "",
        };
        dataSet.push(data);
      });
      state.dataSet = dataSet;
    },
    setDataSet: (
      state: initialStateProps,
      action: {
        payload: {
          moment: MomentsGroupByProps[];
          feature: featureGroupByProps[];
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;
      dataSet.forEach((ele) => {
        const currentMoment = action.payload.moment.find(
          (m) => m.month === ele.month
        );
        if (!isVoid(currentMoment)) {
          ele.moments = currentMoment as MomentsGroupByProps;
        }

        const currentFeature = action.payload.feature.find(
          (f) => f.month === ele.month
        );
        if (!isVoid(currentFeature)) {
          ele.feature = currentFeature as featureGroupByProps;
        }
      });

      state.dataSet = dataSet;
    },
    setDataSetIsLoading: (
      state: initialStateProps,
      action: {
        payload: {
          startDate: Date;
          endDate: Date;
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;
      dataSet.forEach((ele) => {
        if (
          moment(convertMonthStringToDate(ele.month)).utc() >=
            moment(action.payload.startDate).utc() &&
          moment(convertMonthStringToDate(ele.month)).utc() <=
            moment(action.payload.endDate).utc()
        ) {
          ele.isLoading = _LOADINGSTATE.pending;
        }
      });
      state.dataSet = dataSet;
    },
    setDataSetIsLoaded: (
      state: initialStateProps,
      action: {
        payload: {
          startDate: Date;
          endDate: Date;
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;
      dataSet.forEach((ele) => {
        if (
          moment(convertMonthStringToDate(ele.month)).utc() >=
            moment(action.payload.startDate).utc() &&
          moment(convertMonthStringToDate(ele.month)).utc() <=
            moment(action.payload.endDate).utc()
        ) {
          ele.isLoading = _LOADINGSTATE.fullfilled;
        }
      });
      state.dataSet = dataSet;
    },
    setFeatureDataSet: (
      state: initialStateProps,
      action: {
        payload: {
          feature: featureGroupByProps[];
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;

      dataSet.forEach((ele) => {
        const currentFeature = action.payload.feature.find(
          (f) => f.month === ele.month
        );
        if (!isVoid(currentFeature)) {
          ele.feature = currentFeature as featureGroupByProps;
        }
      });

      state.dataSet = dataSet;
    },
    setExcelDownloadFeatureDataSet: (
      state: initialStateProps,
      action: {
        payload: {
          feature: {
            productName: string;
            featureExcelDownload: ModernWorkFeature[];
          }[];
        };
        type: string;
      }
    ) => {
      state.featureExcelDownload = action.payload.feature;
      state.isLoadingExportExcelFeature = false;
      state.isLoadedExportExcelFeature = false;
    },
    setFilterDataSet: (
      state: initialStateProps,
      action: { payload: DataSetProps[]; type: string }
    ) => {
      state.filterDataSet = action.payload;
    },
    setIsLoadingSearchResult: (
      state: initialStateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isLoadingSearchResult = action.payload;
    },
    setIsLoadingExportExcelFeature: (
      state: initialStateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isLoadingExportExcelFeature = action.payload;
    },
    setIsLoadedExportExcelFeature: (
      state: initialStateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isLoadedExportExcelFeature = action.payload;
    },
    setIsExportingExcelFeature: (
      state: initialStateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isExportingExcelFeature = action.payload;
    },
  },
  extraReducers: {
    [initModernWorkTimelineDataSet.fulfilled.type]: (
      state: initialStateProps,
      action: { payload: string[]; type: string }
    ) => {
      let dataSet: DataSetProps[] = [];
      action.payload.map((item, index) => {
        const data = {
          month: item,
          moments: { month: item, moment: [] },
          feature: { month: item, features: [] },
          isLoading: "",
        };
        dataSet.push(data);
      });
      state.dataSet = dataSet;
      state.isMonthLoading = _LOADINGSTATE.fullfilled;
    },
    [initModernWorkTimelineDataSet.rejected.type]: (
      state: initialStateProps
    ) => {
      ErrorMessage.show(
        "There was an error getting timeline months. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [initModernWorkTimelineDataSet.pending.type]: (
      state: initialStateProps
    ) => {
      state.isMonthLoading = _LOADINGSTATE.pending;
    },
    [getModernWorkTimelineFilters.fulfilled.type]: (
      state: initialStateProps,
      action: { payload: ModernWorkFeatureFilter; type: string }
    ) => {
      state.isFilterLoading = _LOADINGSTATE.fullfilled;
      state.modernWorkTimelineFilter = action.payload;
    },
    [getModernWorkTimelineFilters.rejected.type]: (
      state: initialStateProps
    ) => {
      ErrorMessage.show(
        "There was an error getting the Modern Work timeline filter. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getModernWorkTimelineFilters.pending.type]: (state: initialStateProps) => {
      state.isFilterLoading = _LOADINGSTATE.pending;
    },
  },
});

export const { reducer, actions } = modernWorkTimelineSlice;
