import { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import striptags from "striptags";
import "../../Shared/css/timelineGrid.scss";
import "./index.scss";
import { useUrlQueryParam } from "../../../utils/url";
import { MilestoneTypeId } from "../../Shared/constants";
import { RootState, useAppDispatch } from "../../../store";
import * as azureTimelineReducer from "../../../slice/azureTimeline";
import { MomentsGroupByProps } from "../../../slice/timeline";
import { filter, milestoneFilterList } from "../../../slice/savedDataFilters";
import { Milestone } from "../../../slice/milestone";

export interface DataSetProps {
  month: string;
  moments: MomentsGroupByProps;
  milestone: azureTimelineReducer.milestoneGroupByProps;
}

const Content = () => {
  const dataSource = useSelector(
    (state: RootState) => state.azureTimelineReducer.excelDownloadDataSet
  );
  const appDispatch = useAppDispatch();

  const [param] = useUrlQueryParam(
    milestoneFilterList.map((f: filter) => {
      return f.filterName;
    })
  );

  const isLoadingExportExcelMilestone = useSelector(
    (state: RootState) =>
      state.azureTimelineReducer.isLoadingExportExcelMilestone
  );

  const isLoadedExportExcelMilestone = useSelector(
    (state: RootState) =>
      state.azureTimelineReducer.isLoadedExportExcelMilestone
  );

  const isExportingExcelMilestone = useSelector(
    (state: RootState) => state.azureTimelineReducer.isExportingExcelMilestone
  );

  const milestoneDataSet: {
    conversationId: string;
    conversationName: string;
    categoryName: string;
    productName: string;
    productState: number;
    milestoneExcelDownload: Milestone;
  }[] = useMemo(() => {
    let filterMilestones: {
      conversationId: string;
      conversationName: string;
      categoryName: string;
      productName: string;
      productState: number;
      milestoneExcelDownload: Milestone;
    }[] = [];
    dataSource.forEach(
      (product: {
        conversationId: string;
        conversationName: string;
        categoryName: string;
        productName: string;
        productState: number;
        milestoneExcelDownload: Milestone[];
      }) => {
        if (param.ConversationId) {
          if (
            decodeURIComponent(param.ConversationId)
              .split(";|;")
              .filter((item) => item !== "")
              .filter((c) => {
                return product.conversationId === c;
              }).length === 0
          ) {
            return true;
          }
        }

        if (param.ProductName) {
          if (
            decodeURIComponent(param.ProductName)
              .split(";|;")
              .filter((item) => item !== "")
              .indexOf(product.productName) === -1
          ) {
            return true;
          }
        }

        product.milestoneExcelDownload?.forEach((milestone: Milestone) => {
          if (param.ContactName) {
            if (
              decodeURIComponent(param.ContactName) !== milestone.ContactName
            ) {
              return true;
            }
          }
          if (param.MarketingLeadName) {
            if (
              decodeURIComponent(param.MarketingLeadName) !==
              milestone.MarketingLeadName
            ) {
              return true;
            }
          }
          if (param.CreatedBy) {
            if (decodeURIComponent(param.CreatedBy) !== milestone.CreatedBy) {
              return true;
            }
          }
          if (param.ModifiedBy) {
            if (decodeURIComponent(param.ModifiedBy) !== milestone.ModifiedBy) {
              return true;
            }
          }
          if (param.TierId) {
            if (
              decodeURIComponent(param.TierId)
                .split(";|;")
                .filter((item) => item !== "")
                .indexOf(milestone.TierId.toString()) === -1
            ) {
              return true;
            }
          }
          if (param.MilestoneTypeId) {
            if (
              decodeURIComponent(param.MilestoneTypeId)
                .split(";|;")
                .filter((item) => item !== "")
                .indexOf(milestone.MilestoneTypeId.toString()) === -1
            ) {
              return true;
            }
          }
          if (param.MomentId) {
            if (
              (milestone.ModernWorkSecurityEventCalendarMomentsId ===
                undefined ||
                milestone.ModernWorkSecurityEventCalendarMomentsId === null ||
                decodeURIComponent(param.MomentId)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(
                    milestone.ModernWorkSecurityEventCalendarMomentsId.toString()
                  ) === -1) &&
              (milestone.SecondModernWorkSecurityEventCalendarMomentsId ===
                undefined ||
                milestone.SecondModernWorkSecurityEventCalendarMomentsId ===
                  null ||
                decodeURIComponent(param.MomentId)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(
                    milestone.SecondModernWorkSecurityEventCalendarMomentsId.toString()
                  ) === -1)
            ) {
              return true;
            }
          }
          if (param.FilterMilestoneId) {
            if (
              Number(decodeURIComponent(param.FilterMilestoneId)) !==
              milestone.MilestoneId
            ) {
              return true;
            }
          }
          if (param.Status) {
            if (
              decodeURIComponent(param.Status)
                .split(";|;")
                .filter((item) => item !== "")
                .indexOf(milestone.Status.toString()) === -1
            ) {
              return true;
            }
          }
          filterMilestones.push({
            conversationId: product.conversationId,
            conversationName: product.conversationName,
            categoryName: product.categoryName,
            productName: product.productName,
            productState: product.productState,
            milestoneExcelDownload: milestone,
          });
        });
      }
    );

    return filterMilestones;
  }, [dataSource, param]);

  const statuTranfer = (statu: number | null) => {
    return statu == null
      ? "To Be Submitted"
      : statu === 0
      ? "To Be Submitted"
      : statu === 1
      ? "Submitted"
      : statu === 2
      ? "Past Due"
      : "Not Required";
  };

  useEffect(() => {
    if (isExportingExcelMilestone) {
      if (
        isLoadingExportExcelMilestone === false &&
        isLoadedExportExcelMilestone === false
      ) {
        appDispatch(
          azureTimelineReducer.actions.setIsLoadedExportExcelMilestone(true)
        );
      }
    }
  }, [
    appDispatch,
    milestoneDataSet,
    isLoadingExportExcelMilestone,
    isLoadedExportExcelMilestone,
    isExportingExcelMilestone,
  ]);

  return (
    <div className="d-none">
      <table id="timeline-table-excel-download">
        <tbody>
          <tr>
            <td>
              <strong>CSA</strong>
            </td>
            <td>
              <strong>Category</strong>
            </td>
            <td>
              <strong>Product Name</strong>
            </td>
            <td>
              <strong>Name pending</strong>
            </td>
            <td>
              <strong>Milestone ID</strong>
            </td>
            <td>
              <strong>Milestone title</strong>
            </td>
            <td>
              <strong>Milestone type</strong>
            </td>
            <td>
              <strong>PMM</strong>
            </td>
            <td>
              <strong>Marketing team lead</strong>
            </td>
            <td>
              <strong>Engineering owner</strong>
            </td>
            <td>
              <strong>Marketing tier</strong>
            </td>
            <td>
              <strong>Audience</strong>
            </td>
            <td>
              <strong>Public disclosure date</strong>
            </td>
            <td>
              <strong>Field disclosure date</strong>
            </td>
            <td>
              <strong>Close Down Date</strong>
            </td>
            <td>
              <strong>Aligned to moment</strong>
            </td>
            <td>
              <strong>Secondary moment alignment</strong>
            </td>
            <td>
              <strong>Content restrictions</strong>
            </td>
            <td>
              <strong>Milestone link</strong>
            </td>
            <td>
              <strong>Benefit description</strong>
            </td>
          </tr>
          {milestoneDataSet.map(
            (m: {
              conversationName: string;
              categoryName: string;
              productName: string;
              productState: number;
              milestoneExcelDownload: Milestone;
            }) => {
              let isCloseDown =
                m.milestoneExcelDownload.MilestoneTypeId ===
                  MilestoneTypeId.Retired ||
                m.milestoneExcelDownload.MilestoneTypeId ===
                  MilestoneTypeId.Preview_Retired ||
                m.milestoneExcelDownload.MilestoneTypeId ===
                  MilestoneTypeId.GA_Retired;
              return (
                <tr>
                  <td>{m.conversationName}</td>
                  <td>{m.categoryName}</td>
                  <td>{m.productName}</td>
                  <td>{m.productState === 0 ? "Yes" : ""}</td>
                  <td>{m.milestoneExcelDownload.MilestoneId}</td>
                  <td>{m.milestoneExcelDownload.MilestoneName}</td>
                  <td>{m.milestoneExcelDownload.MilestoneTypeName}</td>
                  <td>{m.milestoneExcelDownload.ContactName}</td>
                  <td>{m.milestoneExcelDownload.MarketingLeadName}</td>
                  <td>{m.milestoneExcelDownload.EngineeringContactName}</td>
                  <td>{"Tier " + m.milestoneExcelDownload.Tier}</td>
                  <td>{m.milestoneExcelDownload.MilestoneAudiencesName}</td>
                  <td>
                    {isCloseDown
                      ? ""
                      : m.milestoneExcelDownload.RoadmapStartDate
                      ? moment(
                          new Date(m.milestoneExcelDownload.RoadmapStartDate)
                        )
                          .utc()
                          .format("MM/DD/YYYY")
                      : ""}
                  </td>
                  <td>
                    {isCloseDown
                      ? ""
                      : m.milestoneExcelDownload.DisclosureStartDate
                      ? moment(
                          new Date(m.milestoneExcelDownload.DisclosureStartDate)
                        )
                          .utc()
                          .format("MM/DD/YYYY")
                      : ""}
                  </td>
                  <td>
                    {isCloseDown
                      ? m.milestoneExcelDownload.CloseDownDate
                        ? moment(
                            new Date(m.milestoneExcelDownload.CloseDownDate)
                          ).format("MM/DD")
                        : ""
                      : ""}
                  </td>
                  <td>{m.milestoneExcelDownload.MomentTitle}</td>
                  <td>{m.milestoneExcelDownload.SecondMomentTitle}</td>
                  <td>{m.milestoneExcelDownload.IsNotShare ? "1" : "0"}</td>
                  <td>{m.milestoneExcelDownload.MilestoneLink}</td>
                  <td>
                    {m.milestoneExcelDownload.MilestoneDescription
                      ? striptags(m.milestoneExcelDownload.MilestoneDescription)
                      : ""}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Content;
