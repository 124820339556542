import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ChoiceGroup, IChoiceGroupOption, TextField } from "@fluentui/react";
import {
  MilestoneTypeId,
  _AUTHORIZATIONTYPE,
  TimelineType,
  ModalType,
} from "../../Shared/constants";
import DatePickerWithLabelAndError from "../../Shared/FormInput/datepickerWithLabelAndError";
import Tooltip from "../../Shared/FormInput/tooltip";
import { RootState, useAppDispatch } from "../../../store";
import * as milestoneReducer from "../../../slice/milestone";
import { getModernWorkSecurityEventCalendarMomentListByTimelineType } from "../../../slice/modernWorkSecurityEventCalendarMoment";
import { AppContext } from "../../../App";
import moment from "moment";
import { MilestoneModalContext } from "../../AzureTimeline";
import {
  convertDateTimeToFormatedDateString,
  transferToUtcDate,
} from "../../../utils/date";

const _FeatureSKUService = Object.freeze({
  Feature: 0,
  SKU: 1,
  Service: 2,
});

const MilestoneTypeRelated = (props: {
  milestonetypeId: number;
  submitted: boolean;
  tooltips: { [key: string]: string };
  disabled: boolean;
  setMilestoneFormChanged: Function;
}) => {
  const {
    RoadmapStartDate,
    DisclosureStartDate,
    CloseDownDate,
    FeatureSKUService,
    Answer,
  } = useSelector<RootState, milestoneReducer.Milestone>(
    (state) => state.milestoneReducer.SingleMilestone
  );
  const appDispatch = useAppDispatch();
  const milestoneModalContext = useContext(MilestoneModalContext);
  const appContext = useContext(AppContext);
  const considerAdmin = appContext.accessType !== _AUTHORIZATIONTYPE.admin;
  const customTrainDates = useSelector(
    (state: RootState) => state.calendarReducer.CustomTrainDates
  );
  const dateAtDefault =
    [...customTrainDates].filter(
      (t) => moment(t) > moment(transferToUtcDate(new Date()))
    )[0] || convertDateTimeToFormatedDateString(new Date());
  const isCreated =
    milestoneModalContext.milestoneModalState.modalType === ModalType.New;
  //Only Create new mode this flag is on, and also after user select date, this flag should go off.
  const [isCloseDownDateCreatedInitial, setIsCloseDownDateCreatedInitial] =
    useState(isCreated);
  const [isPublicDateCreatedInitial, setIsPublicDateCreatedInitial] =
    useState(isCreated);
  const [isFieldDateCreatedInitial, setIsFieldDateCreatedInitial] =
    useState(isCreated);
  //And we need to change the initial state of dates
  useEffect(() => {
    if (isCreated) {
      appDispatch(
        milestoneReducer.actions.changeDisclosureStartDate(
          new Date(
            convertDateTimeToFormatedDateString(dateAtDefault)
          ).toISOString()
        )
      );
      appDispatch(
        milestoneReducer.actions.changeRoadmapStartDate(
          new Date(
            convertDateTimeToFormatedDateString(dateAtDefault)
          ).toISOString()
        )
      );
    }
  }, [isCreated]);

  useEffect(() => {
    if (
      props.milestonetypeId === MilestoneTypeId.Retired ||
      props.milestonetypeId === MilestoneTypeId.Preview_Retired ||
      props.milestonetypeId === MilestoneTypeId.GA_Retired
    ) {
      appDispatch(
        milestoneReducer.actions.changeCloseDownDate(
          new Date(
            convertDateTimeToFormatedDateString(dateAtDefault)
          ).toISOString()
        )
      );
    }
  }, [props.milestonetypeId]);

  return (
    <>
      {props.milestonetypeId === MilestoneTypeId.Retired ||
      props.milestonetypeId === MilestoneTypeId.Preview_Retired ||
      props.milestonetypeId === MilestoneTypeId.GA_Retired ? (
        <div>
          <DatePickerWithLabelAndError
            minDate={considerAdmin ? new Date() : undefined}
            label="Close down date *"
            id="milestone_CloseDownDate"
            value={
              isCloseDownDateCreatedInitial
                ? transferToUtcDate(new Date(dateAtDefault))
                : CloseDownDate
                ? transferToUtcDate(new Date(CloseDownDate))
                : new Date()
            }
            onSelectDate={(date: Date | undefined | null) => {
              if (date) {
                appDispatch(
                  milestoneReducer.actions.changeCloseDownDate(
                    new Date(
                      convertDateTimeToFormatedDateString(date)
                    ).toISOString()
                  )
                );
                props.setMilestoneFormChanged(true);
                setIsCloseDownDateCreatedInitial(false);
              }
            }}
            error={
              props.submitted && (!CloseDownDate || CloseDownDate.length === 0)
                ? "Close down date is required."
                : ""
            }
            disabled={props.disabled}
          />
          <ChoiceGroup
            selectedKey={FeatureSKUService.toString()}
            options={[
              {
                key: _FeatureSKUService.Feature.toString(),
                text: "Feature: specify",
              },
              {
                key: _FeatureSKUService.SKU.toString(),
                text: "SKU: specify",
              },
              {
                key: _FeatureSKUService.Service.toString(),
                text: "Service: specify",
              },
            ]}
            onChange={(
              ev: React.FormEvent<HTMLInputElement | HTMLElement> | undefined,
              option: IChoiceGroupOption | undefined
            ) => {
              if (option) {
                appDispatch(
                  milestoneReducer.actions.changeFeatureSKUService(
                    Number(option.key)
                  )
                );
                props.setMilestoneFormChanged(true);
              }
            }}
            label=""
            disabled={props.disabled}
          />
          <TextField
            id="milestone_Answer"
            value={Answer}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue: string | undefined
            ) => {
              appDispatch(
                milestoneReducer.actions.changeAnswer(newValue || "")
              );
              props.setMilestoneFormChanged(true);
            }}
            errorMessage={
              props.submitted && (!Answer || Answer.length === 0)
                ? "Answer is required."
                : ""
            }
            disabled={props.disabled}
          />
        </div>
      ) : (
        <>
          <div>
            <Tooltip
              dynamicContent={props.tooltips["RoadmapStartDateCMMT"] || ""}
            >
              <DatePickerWithLabelAndError
                minDate={considerAdmin ? new Date() : undefined}
                label="Public disclosure date *"
                id="milestone_PublicDisclosureDate"
                value={
                  isPublicDateCreatedInitial
                    ? transferToUtcDate(new Date(dateAtDefault))
                    : RoadmapStartDate
                    ? transferToUtcDate(new Date(RoadmapStartDate))
                    : new Date()
                }
                onSelectDate={(date: Date | undefined | null) => {
                  if (date) {
                    appDispatch(
                      milestoneReducer.actions.changeRoadmapStartDate(
                        new Date(
                          convertDateTimeToFormatedDateString(date)
                        ).toISOString()
                      )
                    );
                    appDispatch(
                      getModernWorkSecurityEventCalendarMomentListByTimelineType(
                        {
                          timelineType: TimelineType.Azure,
                          date: date,
                        }
                      )
                    );
                    props.setMilestoneFormChanged(true);
                    setIsPublicDateCreatedInitial(false);
                  }
                }}
                error={
                  props.submitted &&
                  (!RoadmapStartDate || RoadmapStartDate.length === 0)
                    ? "Public disclosure date is required."
                    : ""
                }
                disabled={props.disabled}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip
              dynamicContent={props.tooltips["DisclosureStartDateCMMT"] || ""}
            >
              <DatePickerWithLabelAndError
                minDate={considerAdmin ? new Date() : undefined}
                label="Field disclosure date *"
                id="milestone_FieldDisclosureDate"
                value={
                  isFieldDateCreatedInitial
                    ? transferToUtcDate(new Date(dateAtDefault))
                    : DisclosureStartDate
                    ? transferToUtcDate(new Date(DisclosureStartDate))
                    : new Date()
                }
                onSelectDate={(date: Date | undefined | null) => {
                  if (date) {
                    appDispatch(
                      milestoneReducer.actions.changeDisclosureStartDate(
                        new Date(
                          convertDateTimeToFormatedDateString(date)
                        ).toISOString()
                      )
                    );
                    props.setMilestoneFormChanged(true);
                    setIsFieldDateCreatedInitial(false);
                  }
                }}
                error={
                  props.submitted &&
                  (!DisclosureStartDate || DisclosureStartDate.length === 0)
                    ? "Field disclosure date is required."
                    : ""
                }
                disabled={props.disabled}
              />
            </Tooltip>
          </div>
        </>
      )}
    </>
  );
};

export default MilestoneTypeRelated;
