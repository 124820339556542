import * as React from "react";
import {
  TextField,
  ILabelStyleProps,
  ILabelStyles,
  Dropdown,
  IStyle,
  IDropdownStyles,
  Toggle,
  IDropdown,
  DefaultButton,
  IDropdownOption,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import TextEditor from "../../Shared/FormInput/textEditor";
//@ts-ignore
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import { useSelector } from "react-redux";
import store, { RootState, useAppDispatch } from "../../../store";
import * as CalendarUpdateReducer from "../../../slice/CalendarSlices/calendarUpdate";
import { useContext, useRef } from "react";
import DatePickerWithLabelAndError from "../../Shared/FormInput/datepickerWithLabelAndError";
import moment from "moment";
import {
  convertDateTimeToFormatedDateString,
  transferToUtcDate,
} from "../../../utils/date";
import GraphUsersAutoComplete from "../../Shared/FormInput/graphUsersAutoComplete";
import { graphUsersAutoCompleteAutoFitContentStyles } from "../../Shared/timelineFilterStyleProps";
import { OnChangeValue } from "react-select";
import { CalendarContext } from "../context";
import { Status } from "../../../slice/CalendarSlices/calendarUpdate";
import { useState } from "react";
import ModalOverlay from "../../Shared/FormInput/modalOverlay";

function getLabelStyles(
  props: Partial<ILabelStyleProps>
): Partial<ILabelStyles> {
  return {
    root: {
      fontWeight: "bold",
      fontSize: "13px",
    },
  };
}
const defaultRootStyle: IStyle = {
  fontSize: "13px",
  marginBottom: "10px",
  maxHeight: "100px",
};

const dropdownStyle: Partial<IDropdownStyles> = {
  root: defaultRootStyle,
  subComponentStyles: {
    label: getLabelStyles,
    multiSelectItem: {},
    panel: {},
  },
  callout: { maxHeight: "320px !important" },
  dropdown: { fontSize: "13px", width: "600px", maxWidth: "600px" },
};
const NewMomentPopup = (props: {}) => {
  const data = useContext(CalendarContext);
  const appDispatch = useAppDispatch();
  const fieldOptionReducer = useSelector(
    (state: RootState) => state.calendarFieldOptionsReducer
  );
  const updateStatus = useSelector(
    (state: RootState) => state.calendarUpdateReducer.updateStatus
  );
  const sulotionAreaDropdownRef = useRef<IDropdown>(null);
  const tierDropdownRef = useRef<IDropdown>(null);
  const dateSelectionAreaDropdownRef = useRef<IDropdown>(null);
  const productFamilyDropdownRef = useRef<IDropdown>(null);
  const momentTypeDropdownRef = useRef<IDropdown>(null);
  const [tbcQuarterFY, setTBCQuarterFY] = useState(
    moment().toDate().getFullYear()
  );
  const [tbcQuarter, setTBCQuarter] = useState(1);
  const [appearErrorInfo, setAppearErrorInfo] = useState(false);
  const considerSubmit = (condition: boolean) => {
    return appearErrorInfo && condition;
  };
  const currentMoment = useSelector(
    (state: RootState) => state.calendarUpdateReducer.currentMoment
  );
  const startDateTemp = useSelector(
    (state: RootState) => state.calendarUpdateReducer.currentMoment?.StartDate
  );
  const startDate =
    startDateTemp === null
      ? undefined
      : transferToUtcDate(new Date(startDateTemp));
  const endDateTemp = useSelector(
    (state: RootState) => state.calendarUpdateReducer.currentMoment?.EndDate
  );
  const endDate =
    endDateTemp === null ? undefined : transferToUtcDate(new Date(endDateTemp));
  const [createButtonDisabled, setDisabled] = useBoolean(false);
  const [momentFormSubmited, setmomentFormSubmited] = useState(false);
  const [momentFormChanged, setmomentFormChanged] = useState(false);
  const confirmationShow = useRef<HTMLButtonElement>(null);

  const DateSection = () => {
    if (currentMoment.DateSelection == "Specific Date") {
      return (
        <div id="Specific Date">
          <DatePickerWithLabelAndError
            label="Start date"
            minDate={new Date(2021, 11, 13, 1, 1)}
            value={startDate}
            onSelectDate={(date: Date | null | undefined) => {
              if (date) {
                var selectedStartDate =
                  convertDateTimeToFormatedDateString(date);
                appDispatch(
                  CalendarUpdateReducer.actions.changeStartDate(
                    selectedStartDate
                  )
                );
              }
            }}
            blockErrorLabel={true}
            onFormatDate={(date?: Date): string => {
              return !date ? "" : moment(date).format("MM/DD/YYYY");
            }}
            id="momentsSA_startDate"
            error={
              considerSubmit(
                startDate !== undefined &&
                  endDate !== undefined &&
                  startDate > endDate
              )
                ? "Start date must come BEFORE End date"
                : ""
            }
          />
          <DatePickerWithLabelAndError
            label="End date"
            minDate={new Date(2021, 11, 13, 1, 1)}
            value={endDate}
            onSelectDate={(date: Date | null | undefined) => {
              if (date) {
                var selectedEndDate = convertDateTimeToFormatedDateString(date);
                appDispatch(
                  CalendarUpdateReducer.actions.changeEndDate(selectedEndDate)
                );
              }
            }}
            onFormatDate={(date?: Date): string => {
              return !date ? "" : moment(date).format("MM/DD/YYYY");
            }}
            id="momentsSA_endDate"
            error={
              considerSubmit(
                startDate !== undefined &&
                  endDate !== undefined &&
                  startDate > endDate
              )
                ? "End date must come AFTER Start date"
                : ""
            }
            blockErrorLabel={true}
          />
        </div>
      );
    }
    if (currentMoment.DateSelection == "Watch Day") {
      return (
        <div id="Watch Day">
          <DatePickerWithLabelAndError
            label="Start date"
            minDate={new Date(2021, 11, 13, 1, 1)}
            value={startDate}
            onSelectDate={(date: Date | null | undefined) => {
              if (date) {
                let startDate = moment(date).hour(0).minute(0).second(0);
                let endDate = moment(date).hour(23).minute(59).second(59);
                appDispatch(
                  CalendarUpdateReducer.actions.changeStartDate(
                    startDate.format("YYYY-MM-DD")
                  )
                );
                appDispatch(
                  CalendarUpdateReducer.actions.changeEndDate(
                    endDate.format("YYYY-MM-DD")
                  )
                );
              }
            }}
            onFormatDate={(date?: Date): string => {
              return !date ? "" : moment(date).format("MM/DD/YYYY");
            }}
            id="momentsSA_startDate"
            error={""}
          />
        </div>
      );
    }
    if (currentMoment.DateSelection == "TBC - Month") {
      return (
        <div id="TBC - Month">
          <label style={{ fontWeight: "bold", fontSize: "13px" }}>Month</label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              marginTop: "10px",
            }}
          >
            <label
              style={{ width: "50%", fontWeight: "bold", fontSize: "13px" }}
            >
              Start
            </label>
            <label
              style={{ width: "50%", fontWeight: "bold", fontSize: "13px" }}
            >
              End
            </label>
            <div style={{ width: "50%", display: "flex" }}>
              <Dropdown
                styles={{ root: { width: "60%", paddingRight: "2px" } }}
                multiSelect={false}
                selectedKey={
                  startDate !== undefined ? startDate.getMonth() + 1 : 1
                }
                options={fieldOptionReducer.TBCMonthSelection}
                onChange={(e, i) => {
                  appDispatch(
                    CalendarUpdateReducer.actions.changeTBCMonthDate({
                      date: i?.key as number,
                      dateType: "StartDateMonth",
                    })
                  );
                }}
                errorMessage={
                  considerSubmit(
                    startDate !== undefined &&
                      endDate !== undefined &&
                      startDate > endDate
                  )
                    ? "Start date must come BEFORE End date"
                    : ""
                }
              />
              <Dropdown
                styles={{ root: { width: "40%", paddingRight: "2px" } }}
                selectedKey={
                  startDate !== undefined ? startDate.getFullYear() : 2021
                }
                options={fieldOptionReducer.TBCYearSelection}
                onChange={(e, i) => {
                  appDispatch(
                    CalendarUpdateReducer.actions.changeTBCMonthDate({
                      date: i?.key as number,
                      dateType: "StartYear",
                    })
                  );
                }}
              />
            </div>
            <div style={{ width: "50%", display: "flex" }}>
              <Dropdown
                styles={{ root: { width: "60%", paddingRight: "2px" } }}
                selectedKey={endDate !== undefined ? endDate.getMonth() + 1 : 1}
                options={fieldOptionReducer.TBCMonthSelection}
                onChange={(e, i) => {
                  appDispatch(
                    CalendarUpdateReducer.actions.changeTBCMonthDate({
                      date: i?.key as number,
                      dateType: "EndDateMonth",
                    })
                  );
                }}
                errorMessage={
                  considerSubmit(
                    startDate !== undefined &&
                      endDate !== undefined &&
                      startDate > endDate
                  )
                    ? "End date must come BEFORE End date"
                    : ""
                }
              />
              <Dropdown
                styles={{ root: { width: "40%" } }}
                options={fieldOptionReducer.TBCYearSelection}
                selectedKey={
                  endDate !== undefined ? endDate.getFullYear() : 2021
                }
                onChange={(e, i) => {
                  appDispatch(
                    CalendarUpdateReducer.actions.changeTBCMonthDate({
                      date: i?.key as number,
                      dateType: "EndYear",
                    })
                  );
                }}
              />
            </div>
          </div>
          <Dropdown
            label="TBC Category"
            options={fieldOptionReducer.TBCCategory}
            placeholder={"Find items"}
            selectedKey={currentMoment.TBCCategory}
            styles={dropdownStyle}
          />
        </div>
      );
    }
    if (currentMoment.DateSelection == "TBC - Quarter") {
      return (
        <div id="TBC - Quarter">
          <label style={{ fontWeight: "bold", fontSize: "10pt" }}>
            Quarter
          </label>
          <div
            style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
          >
            <Dropdown
              styles={{ root: { width: "30%", paddingRight: "2px" } }}
              selectedKey={tbcQuarterFY}
              options={fieldOptionReducer.TBCFYYearSelection}
              onChange={(e, i) => {
                setTBCQuarterFY(i?.key as number);
              }}
            />
            <Dropdown
              styles={{ root: { width: "70%" } }}
              options={fieldOptionReducer.TBCQuarterSelection}
              selectedKey={tbcQuarter}
              onChange={(e, o, index) => {
                setTBCQuarter(o?.key as number);
                appDispatch(
                  CalendarUpdateReducer.actions.changeTBCQuarter({
                    fisicalYear: tbcQuarterFY,
                    quarter: o?.key as number,
                  })
                );
              }}
            />
          </div>
          <Dropdown
            label="TBC Category"
            options={fieldOptionReducer.TBCCategory}
            placeholder={"Find items"}
            selectedKey={currentMoment.TBCCategory}
            styles={dropdownStyle}
          />
        </div>
      );
    }
    return <div />;
  };
  return (
    <ModalOverlay
      refs={data.modalRef}
      title={
        updateStatus == Status.Add
          ? "Add a new marketing moment"
          : "Moment ID: " + currentMoment.Id
      }
      className="NewMomentPopup"
      formSubmited={momentFormSubmited}
      setFormSubmited={[setmomentFormSubmited, setmomentFormChanged]}
      confirmationShow={confirmationShow}
      formChanged={momentFormChanged}
      mainStyle={{
        maxHeight: "inherit",
        minWidth: "700px",
        maxWidth: "1174px",
      }}
    >
      <div>
        <div className="dialogContent">
          <TextField
            label="Title"
            id="momentTitle"
            required={true}
            value={currentMoment.Title}
            onChange={(e, v) => {
              appDispatch(CalendarUpdateReducer.actions.changeTitle(v ?? ""));
            }}
            styles={{
              root: defaultRootStyle,
              field: { fontSize: "13px" },
              subComponentStyles: { label: getLabelStyles },
            }}
            errorMessage={
              considerSubmit(currentMoment.Title === "" || !currentMoment.Title)
                ? "Title is required."
                : currentMoment.Title.length > 100
                ? "Title exceeds 100 characters"
                : ""
            }
          />
          <Dropdown
            label="Tier"
            required={true}
            options={fieldOptionReducer.Tier}
            componentRef={tierDropdownRef}
            id="MilstoneTier"
            selectedKey={currentMoment.MomentTier}
            placeholder="Select an option"
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              item?: IDropdownOption
            ) => {
              appDispatch(
                CalendarUpdateReducer.actions.changeTier(item ? item.text : "")
              );
            }}
            errorMessage={
              considerSubmit(currentMoment.MomentTier === "")
                ? "Tier is required."
                : ""
            }
            styles={dropdownStyle}
            ariaLabel="Marketing tier, required field"
          />
          <Dropdown
            label="Date selection"
            required={true}
            options={fieldOptionReducer.DateSelection}
            componentRef={dateSelectionAreaDropdownRef}
            placeholder={"Find items"}
            selectedKey={currentMoment.DateSelection}
            onChange={(e, v) => {
              appDispatch(
                CalendarUpdateReducer.actions.changeDateSelection(
                  v ? v.text : ""
                )
              );
            }}
            errorMessage={
              considerSubmit(
                currentMoment.DateSelection === "" ||
                  !currentMoment.DateSelection
              )
                ? "Date selection is required."
                : undefined
            }
            styles={dropdownStyle}
          />
          {DateSection()}
          <Dropdown
            label="Solution area"
            componentRef={sulotionAreaDropdownRef}
            id="momentSolutionAreaDropdown"
            required={true}
            options={fieldOptionReducer.SolutionArea}
            placeholder={"Find items"}
            multiSelect={true}
            selectedKeys={currentMoment.SolutionArea.filter(
              (p) =>
                fieldOptionReducer.SolutionArea.findIndex(
                  (d) => d.key === p
                ) !== -1
            )}
            onChange={(e, o, i) => {
              appDispatch(
                CalendarUpdateReducer.actions.editSolutionArea({
                  index: o?.key as string,
                  selected: o!.selected ?? false,
                })
              );
            }}
            styles={dropdownStyle}
            errorMessage={
              considerSubmit(
                currentMoment.SolutionArea.filter(
                  (p) =>
                    fieldOptionReducer.SolutionArea.findIndex(
                      (d) => d.key === p
                    ) !== -1
                ).length === 0
              )
                ? "Solution area is required"
                : ""
            }
          />
          <Dropdown
            label="Product family"
            componentRef={productFamilyDropdownRef}
            id="momentProductFamilyDropdown"
            required={true}
            options={fieldOptionReducer.ProductFamily}
            placeholder={"Find items"}
            multiSelect={true}
            selectedKeys={currentMoment.ProductFamily?.filter(
              (p) =>
                fieldOptionReducer.ProductFamily?.findIndex(
                  (d) => d.key === p
                ) !== -1
            )}
            onChange={(e, o, i) => {
              appDispatch(
                CalendarUpdateReducer.actions.editProductFamily({
                  index: o?.key as string,
                  selected: o!.selected ?? false,
                })
              );
            }}
            errorMessage={
              currentMoment.ProductFamily
                ? considerSubmit(
                    currentMoment.ProductFamily?.filter(
                      (p) =>
                        fieldOptionReducer.ProductFamily?.findIndex(
                          (d) => d.key === p
                        ) !== -1
                    ).length === 0
                  )
                  ? "Product family is required"
                  : ""
                : "Product family is required"
            }
            styles={dropdownStyle}
          />
          <Dropdown
            label="Moment type"
            componentRef={momentTypeDropdownRef}
            id="momentMomentTypeDropdown"
            required={true}
            options={fieldOptionReducer.MomentType}
            placeholder={"Find items"}
            multiSelect={true}
            selectedKeys={currentMoment.MomentType.filter(
              (p) =>
                fieldOptionReducer.MomentType.findIndex((d) => d.key === p) !==
                -1
            )}
            onChange={(e, o, i) => {
              appDispatch(
                CalendarUpdateReducer.actions.editMomentType({
                  index: o?.key as string,
                  selected: o!.selected ?? false,
                })
              );
            }}
            errorMessage={
              considerSubmit(
                currentMoment.MomentType.filter(
                  (p) =>
                    fieldOptionReducer.MomentType.findIndex(
                      (d) => d.key === p
                    ) !== -1
                ).length === 0
              )
                ? "Moment type is required"
                : ""
            }
            styles={dropdownStyle}
          />
          <TextEditor
            id="milestone_description"
            label="Description"
            value={currentMoment.Description ?? ""}
            onChangeHandler={(e, editor) => {
              appDispatch(
                CalendarUpdateReducer.actions.changeDescription(
                  editor.getData()
                )
              );
            }}
            disabled={false}
            onBlurHandler={() => {}}
            editor={ClassicEditor}
            labelStyle={{
              fontSize: "13px",
              fontWeight: "bold",
              marginTop: "0px",
            }}
          />
          <label
            style={{
              width: "50%",
              fontWeight: "bold",
              fontSize: "13px",
              marginTop: "10px",
            }}
          >
            Contact(s)
          </label>
          <GraphUsersAutoComplete
            selectStyle={graphUsersAutoCompleteAutoFitContentStyles}
            selectedKeys={currentMoment.Contacts}
            onChangeHandler={(v: OnChangeValue<any, true>, action) => {
              appDispatch(
                CalendarUpdateReducer.actions.editContacts({
                  index: v.map((p) => p.label as string),
                  selected: true,
                })
              );
            }}
            isMulti={true}
            microsoftUser={true}
            ariaLabel="Contact"
            id="filter_MarketingLead_Name"
          ></GraphUsersAutoComplete>

          {/*<Dropdown*/}
          {/*  label="PR support"*/}
          {/*  options={fieldOptionReducer.PRSupport}*/}
          {/*  placeholder={"Find items"}*/}
          {/*  selectedKey={currentMoment.PRSupport}*/}
          {/*  onChange={(e, o, i) => {*/}
          {/*    appDispatch(*/}
          {/*      CalendarUpdateReducer.actions.editPRSupport(*/}
          {/*        (o?.key as number) ?? 0*/}
          {/*      )*/}
          {/*    );*/}
          {/*  }}*/}
          {/*  styles={{*/}
          {/*    ...dropdownStyle,*/}
          {/*    root: { marginTop: "10px", marginBottom: "10px" },*/}
          {/*  }}*/}
          {/*/>*/}
          <Dropdown
            label="Main disclosure channel"
            options={fieldOptionReducer.MainDisclosureChannel}
            placeholder={"Find items"}
            multiSelect={true}
            selectedKeys={currentMoment.MainDisclosureChannel}
            onChange={(e, o, i) => {
              appDispatch(
                CalendarUpdateReducer.actions.editMainDisclosureChannel({
                  index: o?.key as string,
                  selected: o!.selected ?? false,
                })
              );
            }}
            styles={{
              ...dropdownStyle,
              root: {
                fontSize: "13px",
                marginBottom: "10px",
                maxHeight: "100px",
                marginTop: "10px",
              },
            }}
          />
          <TextField
            label="Geo location"
            value={currentMoment.GeoLocation}
            id="momentGeoLocation"
            onChange={(e, v) => {
              appDispatch(
                CalendarUpdateReducer.actions.changeGeoLocation(v ?? "")
              );
            }}
            styles={{
              root: defaultRootStyle,
              field: { fontSize: "13px" },
              subComponentStyles: { label: getLabelStyles },
            }}
            errorMessage={
              currentMoment.GeoLocation != undefined &&
              currentMoment.GeoLocation.length > 200
                ? "Geo location exceeds 200 characters"
                : ""
            }
          />
          <Dropdown
            label="Target audience"
            options={fieldOptionReducer.TargetAudience}
            placeholder={"Find items"}
            multiSelect={true}
            selectedKeys={currentMoment.TargetAudience}
            onChange={(e, o, i) => {
              appDispatch(
                CalendarUpdateReducer.actions.editTargetAudience({
                  index: (o?.key as string) ?? "",
                  selected: o!.selected ?? false,
                })
              );
            }}
            styles={dropdownStyle}
          />
          <Toggle
            label="News zone"
            onText="On"
            offText="Off"
            checked={currentMoment.NewsZone}
            onChange={(e, c) => {
              appDispatch(
                CalendarUpdateReducer.actions.changeNewsZone(c ?? false)
              );
            }}
            styles={{
              root: { defaultRootStyle },
              label: { fontSize: "13px", fontWeight: "bold" },
              thumb: { height: "15px", width: "15px" },
              pill: {
                height: "21px",
                width: "40px",
                background: currentMoment.NewsZone
                  ? "rgb(96, 94, 92)"
                  : "white",
                ":hover": {
                  background: "rgb(96, 94, 92)",
                },
              },
              text: { fontSize: "13px", fontWeight: "Bold" },
            }}
          />
          <div
            id="newsZoneOn"
            style={{ display: currentMoment.NewsZone ? "block" : "none" }}
          >
            <Dropdown
              label="Solution Area (News Zone)"
              required={false}
              options={fieldOptionReducer.SolutionArea.filter(
                (p) =>
                  currentMoment.SolutionArea?.findIndex(
                    (s) => s == (p.text as string)
                  ) != -1
              )}
              defaultSelectedKey={currentMoment.SolutionAreaNewsZone}
              selectedKey={currentMoment.SolutionAreaNewsZone}
              onChange={(e, o, i) => {
                appDispatch(
                  CalendarUpdateReducer.actions.editNewsZoneSolutionArea(
                    (o?.key as string) ?? ""
                  )
                );
              }}
              styles={dropdownStyle}
            />
            <TextField
              label="Priority Item"
              required={false}
              value={currentMoment.PriorityItem}
              onChange={(e, v) => {
                appDispatch(
                  CalendarUpdateReducer.actions.changePriorityItem(v ?? "")
                );
              }}
              styles={{
                root: defaultRootStyle,
                field: { fontSize: "13px" },
                subComponentStyles: { label: getLabelStyles },
              }}
            />
            <TextField
              label="Barriers to success"
              required={false}
              value={currentMoment.BarriersToSuccess}
              onChange={(e, v) => {
                appDispatch(
                  CalendarUpdateReducer.actions.changeBarriersToSuccess(v ?? "")
                );
              }}
              styles={{
                root: defaultRootStyle,
                field: { fontSize: "13px" },
                subComponentStyles: { label: getLabelStyles },
              }}
            />
          </div>
          <DefaultButton
            text={updateStatus == Status.Add ? "Create" : "Update"}
            disabled={createButtonDisabled}
            onClick={() => {
              if (!currentMoment.Title || currentMoment.Title === "") {
                setAppearErrorInfo(true);
                document.getElementById("momentTitle")?.focus();
                return;
              }
              if (tierDropdownRef.current?.selectedOptions.length === 0) {
                setAppearErrorInfo(true);
                tierDropdownRef.current?.focus();
                return;
              }
              if (
                dateSelectionAreaDropdownRef.current?.selectedOptions.length ===
                0
              ) {
                setAppearErrorInfo(true);
                dateSelectionAreaDropdownRef.current?.focus();
                return;
              }
              if (
                startDate !== undefined &&
                endDate !== undefined &&
                startDate > endDate &&
                currentMoment.DateSelection
              ) {
                setAppearErrorInfo(true);
                document.getElementById(currentMoment.DateSelection)?.focus();
                return;
              }
              if (
                sulotionAreaDropdownRef.current?.selectedOptions.length === 0
              ) {
                setAppearErrorInfo(true);
                sulotionAreaDropdownRef.current?.focus();
                return;
              }
              if (
                productFamilyDropdownRef.current?.selectedOptions.length === 0
              ) {
                setAppearErrorInfo(true);
                sulotionAreaDropdownRef.current?.focus();
                return;
              }
              if (momentTypeDropdownRef.current?.selectedOptions.length === 0) {
                setAppearErrorInfo(true);
                sulotionAreaDropdownRef.current?.focus();
                return;
              }
              if (
                currentMoment.GeoLocation != undefined &&
                currentMoment.GeoLocation.length > 200
              ) {
                setAppearErrorInfo(true);
                document.getElementById("momentGeoLocation")?.focus();
                return;
              }
              setDisabled.setTrue();
              if (updateStatus == Status.Add) {
                appDispatch(
                  CalendarUpdateReducer.addCalendar(
                    store.getState().calendarUpdateReducer.currentMoment
                  )
                ).then((p) => {
                  setDisabled.setFalse();
                  appDispatch(
                    CalendarUpdateReducer.actions.changeEditPanelOpen(false)
                  );
                  data.modalRef?.current?.click();
                  data.getModernWorkSecurityEventCalendarMomentList();
                });
              } else if (updateStatus == Status.Edit) {
                appDispatch(
                  CalendarUpdateReducer.putMoment(
                    store.getState().calendarUpdateReducer.currentMoment
                  )
                ).then((p) => {
                  setDisabled.setFalse();
                  appDispatch(
                    CalendarUpdateReducer.actions.changeEditPanelOpen(false)
                  );
                  data.modalRef?.current?.click();
                  data.getModernWorkSecurityEventCalendarMomentList();
                });
              }
            }}
            styles={{
              root: {
                ...defaultRootStyle,
                height: "40px",
                width: "90px",
                marginTop: "20px",
                marginBottom: "20px",
              },
              label: { fontSize: "13px", fontWeight: "bold" },
            }}
          />
        </div>
      </div>
    </ModalOverlay>
  );
};

export default NewMomentPopup;
