import axios from "axios";
import moment from "moment";
import { isVoid } from "../utils/utils";
import { ErrorMessage } from "../components/Shared/messageBox";

const instance = axios.create({
  baseURL: "/api/",
  timeout: 60000 * 10,
  headers: {
    "content-type": "application/json",
  },
});

const CancelToken = axios.CancelToken;
let cancel = () => {};

const checkAuthorization = () => {
  if (isVoid(localStorage.getItem("accessToken"))) {
    cancel();
    window.location.href = window.location.href;
  } else if (
    !isVoid(localStorage.getItem("expire")) &&
    moment(localStorage.getItem("expire")).toDate() < new Date(2025, 0, 3)
  ) {
    cancel();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessType");
    localStorage.removeItem("expire");
    window.location.href = window.location.href;
  }
};

instance.interceptors.request.use(
  (config) => {
    config.cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    });
    checkAuthorization();
    config.headers["Authorization"] =
      "Bearer " + localStorage.getItem("accessToken");
    return config;
  },
  (error) => {
    ErrorMessage.show(error.message);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data);
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      cancel();
      localStorage.removeItem("accessToken");
      window.location.href = window.location.href;
    } else if (error.response && error.response.status === 403) {
      if (localStorage.getItem("accessType") !== "ACCESS_DENIED") {
        cancel();
        localStorage.removeItem("accessType");
        window.location.href = window.location.href;
      }
    } else {
      return Promise.reject(error);
    }
  }
);

const get = (url: string, params = {}) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (error.response && error.response.status !== 401) {
            reject(error);
          }
        }
      )
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          reject(error);
        }
      });
  });
};

const postBlob = (url: string, params = {}) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/octet-stream",
        },
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (error.response && error.response.status !== 401) {
            reject(error);
          }
        }
      )
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          reject(error);
        }
      });
  });
};

const getBlob = (url: string, params = {}) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        method: "get",
        params: params,
        responseType: "blob",
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (error.response && error.response.status !== 401) {
            reject(error);
          }
        }
      )
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          reject(error);
        }
      });
  });
};

const post = (url: string, data: any) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data)
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (error.response && error.response.status !== 401) {
            reject(error);
          }
        }
      )
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          reject(error);
        }
      });
  });
};

const patch = (url: string, data: any) => {
  return new Promise((resolve, reject) => {
    instance
      .patch(url, data)
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (error.response && error.response.status !== 401) {
            reject(error);
          }
        }
      )
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          reject(error);
        }
      });
  });
};

const put = (url: string, data: any) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, data)
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (error.response && error.response.status !== 401) {
            reject(error);
          }
        }
      )
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          reject(error);
        }
      });
  });
};

const del = (url: string) => {
  return new Promise((resolve, reject) => {
    instance
      .delete(url)
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (error.response && error.response.status !== 401) {
            reject(error);
          }
        }
      )
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          reject(error);
        }
      });
  });
};

const postWithForm = (url: string, data: any) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (error.response && error.response.status !== 401) {
            reject(error);
          }
        }
      )
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          reject(error);
        }
      });
  });
};

const httpClient = {
  get,
  getBlob,
  post,
  patch,
  put,
  del,
  postWithForm,
  postBlob,
};

export default httpClient;
