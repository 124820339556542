import { useContext } from "react";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { formatCYDate, formatDate } from "../../../../utils/date";
import { isVoid } from "../../../../utils/utils";
import { modernWorkTimelineFeature } from "../../../../slice/modernWorkTimeline";
import { ModernWorkFeatureModalContext } from "../../index";
import { truncateText, highlightSearch } from "../../../../utils/utils";
import { MilestoneStatusIcon } from "../../../AzureTimeline/NavBar";

const FeatureSearchRow = (props: { feature: modernWorkTimelineFeature }) => {
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );

  const featureTitle = !isVoid(props.feature.MarketingTitle)
    ? props.feature.MarketingTitle
    : !isVoid(props.feature.PublicTitle)
    ? props.feature.PublicTitle
    : !isVoid(props.feature.Title)
    ? props.feature.Title
    : "";

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            modernWorkFeatureModalContext.searchHighlightPhrase,
            props.feature.CCHFeatureId.toString()
          ),
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            modernWorkFeatureModalContext.searchHighlightPhrase,
            props.feature.RoadmapId
          ),
        }}
      />
      <div
        title={featureTitle}
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            modernWorkFeatureModalContext.searchHighlightPhrase,
            truncateText(featureTitle, 75)
          ),
        }}
      />
      <div>
        {props.feature.Tier === "1"
          ? "Tier 1"
          : props.feature.Tier === "2"
          ? "Tier 2"
          : props.feature.Tier === "3"
          ? "Tier 3"
          : ""}
      </div>
      <div>
        {!props.feature.PreviewDate
          ? ""
          : formatCYDate(props.feature.PreviewDate)}
      </div>
      <div>
        {!props.feature.GADate ? "" : formatCYDate(props.feature.GADate)}
      </div>
      <div>{formatDate(props.feature.PlannedProductionDate)}</div>
      <div>{formatDate(props.feature.PublicDisclosureDate)}</div>
      <div
        dangerouslySetInnerHTML={{
          __html:
            highlightSearch(
              modernWorkFeatureModalContext.searchHighlightPhrase,
              props.feature.ModernWorkSecurityEventCalendarMomentsTitle
            ) +
            (props.feature.SecondModernWorkSecurityEventCalendarMomentsId ===
              null ||
            props.feature.SecondModernWorkSecurityEventCalendarMomentsId === 0
              ? ""
              : '<i class="ms-Icon root-43" data-icon-name="FavoriteStarFill" aria-hidden="true" aria-label="Second Moment"></i>'),
        }}
      />
      <div
        title={props.feature.PMGContact}
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            modernWorkFeatureModalContext.searchHighlightPhrase,
            truncateText(props.feature.PMGContact, 69)
          ),
        }}
      />
      <div>
        <span>
          {
            MilestoneStatusIcon[
              props.feature.TierId && props.feature.TierId !== 0 ? 4 : 5
            ]
          }
        </span>
      </div>
    </>
  );
};

export default FeatureSearchRow;
