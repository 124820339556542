import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import { ErrorMessage } from "../components/Shared/messageBox";
import modernWorkFeatureService from "../services/modernWorkFeature";
import * as historyReducer from "./modernWorkFeatureHistory";
export interface ModernWorkFeature {
  Id: number;
  Title: string;
  SourceADOID: string;
  RoadmapId: string;
  PublicTitle: string;
  Publicdescription: string;
  PMGApproved: boolean;
  Status: string;
  PublicRoadmapStatus: string;
  PMGContact: string;
  PMGContactEmail: string;
  PMContact: string;
  PMContactEmail: string;
  Product: string;
  AllProducts?: string;
  CloudInstance: string;
  CustomerRings: string;
  Platform: string;
  MoreInfoLink: string;
  PublicRoadmap: string;
  ClientOrService: string;
  MSETStags: string;
  PreviewDate: string;
  PreviewDelayed: boolean;
  GADate: string;
  GADelayed: boolean;
  LaunchDate: string;
  DatePublishedToPublicRoadmap: string;
  LastModifiedOnPublicRoadmap: string;
  DisclosureDate: string | null;
  LastDisclosureDate: string | null;
  CreatedBy: string;
  CreatedByEmail: string;
  Created: string;
  ModifiedBy: string;
  ModifiedByEmail: string;
  Modified: string;
  IsActive: boolean;
  IsDelayed: boolean;
  TriageTagId: number | null;
  TriageTagIds: number[] | null;
  TriageTagName: string[];
  TierId: number | null;
  Tier: string;
  TriageDescription: string;
  FeatureLink: string;
  ModernWorkSecurityEventCalendarMomentsId: number | null;
  ModernWorkSecurityEventCalendarMomentsTitle: string;
  SecondModernWorkSecurityEventCalendarMomentsId: number | null;
  SecondModernWorkSecurityEventCalendarMomentsTitle: string;
  PublicDisclosureDate: string | null;
  PlannedProductionDate: string | null;
  ServiceTargetDateDoD: string;
  ServiceTargetDateGCC: string;
  ServiceTargetDateGCCH: string;
  MarketingDescription: string;
  MarketingDescriptionApproved: boolean;
  FeatureTheme: string;
  MarketingTitle: string;
  MarketingTitleApproved: boolean;
  ModernWOrkFeatureProductAreaName: string;
  ModernWorkFeatureProductAreaId: number | null;
  FinalTitle: string;
  FinalDescription: string;
  CCHFeatureId: number;
  ReleaseFork: string;
  MessageCenterId: string;
  __isLoading: string;
  __isFormValid: boolean;
}

export const initialModernWorkFeatureState: ModernWorkFeature = {
  Id: 0,
  Title: "",
  SourceADOID: "",
  RoadmapId: "",
  PublicTitle: "",
  Publicdescription: "",
  PMGApproved: false,
  Status: "",
  PublicRoadmapStatus: "",
  PMGContact: "",
  PMGContactEmail: "",
  PMContact: "",
  PMContactEmail: "",
  Product: "",
  AllProducts: "",
  CloudInstance: "",
  CustomerRings: "",
  Platform: "",
  MoreInfoLink: "",
  PublicRoadmap: "",
  ClientOrService: "",
  MSETStags: "",
  PreviewDate: "",
  PreviewDelayed: false,
  GADate: "",
  GADelayed: false,
  LaunchDate: "",
  DatePublishedToPublicRoadmap: "",
  LastModifiedOnPublicRoadmap: "",
  DisclosureDate: null,
  LastDisclosureDate: null,
  CreatedBy: "",
  CreatedByEmail: "",
  Created: "",
  ModifiedBy: "",
  ModifiedByEmail: "",
  Modified: "",
  IsActive: false,
  IsDelayed: false,
  TriageTagId: null,
  TriageTagIds: null,
  TriageTagName: [],
  TierId: null,
  Tier: "",
  TriageDescription: "",
  FeatureLink: "",
  ModernWorkSecurityEventCalendarMomentsId: null,
  ModernWorkSecurityEventCalendarMomentsTitle: "",
  SecondModernWorkSecurityEventCalendarMomentsId: null,
  SecondModernWorkSecurityEventCalendarMomentsTitle: "",
  PublicDisclosureDate: null,
  PlannedProductionDate: "",
  ServiceTargetDateDoD: "",
  ServiceTargetDateGCC: "",
  ServiceTargetDateGCCH: "",
  MarketingDescription: "",
  MarketingDescriptionApproved: false,
  FeatureTheme: "",
  MarketingTitle: "",
  MarketingTitleApproved: false,
  ModernWOrkFeatureProductAreaName: "",
  ModernWorkFeatureProductAreaId: null,
  FinalTitle: "",
  FinalDescription: "",
  CCHFeatureId: 0,
  MessageCenterId: "",
  ReleaseFork: "",
  __isLoading: _LOADINGSTATE.pending,
  __isFormValid: false,
};

export interface ModernWorkFeatureByTier {
  tier: string;
  features: ModernWorkFeature[];
}

export interface initialStateProps {
  modernWorkFeature: ModernWorkFeature;
  mSETSFeaturesFilterMSETSIds: {
    isLoading: string;
  };
}

const initialState: initialStateProps = {
  modernWorkFeature: initialModernWorkFeatureState,
  mSETSFeaturesFilterMSETSIds: {
    isLoading: _LOADINGSTATE.pending,
  },
};

export const getFeature = createAsyncThunk(
  "getFeature",
  async (featureId: number) => {
    let feature = (await modernWorkFeatureService.getFeature(
      featureId
    )) as ModernWorkFeature;
    return feature;
  }
);

export const getFeatureByMomentId = createAsyncThunk(
  "getFeatureByMomentId",
  async (id: number) => {
    return await modernWorkFeatureService.getFeatureByMomentId(id);
  }
);

export const updateFeature = createAsyncThunk(
  "updateFeature",
  async (model: ModernWorkFeature) => {
    return await modernWorkFeatureService.updateFeature(model);
  }
);

const modernWorkFeatureSlice = createSlice({
  name: "moderWorkMSETS",
  initialState: initialState,
  reducers: {
    reset: (state: initialStateProps) => {
      state.modernWorkFeature = initialModernWorkFeatureState;
    },
    changeProductAreaId: (
      state: initialStateProps,
      action: { payload: number | null; type: string }
    ) => {
      state.modernWorkFeature.ModernWorkFeatureProductAreaId = action.payload;
    },
    changeFeatureTheme: (
      state: initialStateProps,
      action: { payload: string; type: string }
    ) => {
      state.modernWorkFeature.FeatureTheme = action.payload;
    },
    changeTierId: (
      state: initialStateProps,
      action: { payload: number | null; type: string }
    ) => {
      state.modernWorkFeature.TierId = action.payload;
    },
    changeMomentId: (
      state: initialStateProps,
      action: { payload: number | null; type: string }
    ) => {
      state.modernWorkFeature.ModernWorkSecurityEventCalendarMomentsId =
        action.payload;
    },
    changeSecondMomentId: (
      state: initialStateProps,
      action: { payload: number | null; type: string }
    ) => {
      state.modernWorkFeature.SecondModernWorkSecurityEventCalendarMomentsId =
        action.payload;
    },
    changeMarketingTitle: (
      state: initialStateProps,
      action: { payload: string; type: string }
    ) => {
      state.modernWorkFeature.MarketingTitle = action.payload;
    },
    changeMarketingTitleApproved: (
      state: initialStateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.modernWorkFeature.MarketingTitleApproved = action.payload;
    },
    changeMarketingDescription: (
      state: initialStateProps,
      action: { payload: string; type: string }
    ) => {
      state.modernWorkFeature.MarketingDescription = action.payload;
    },
    changeMarketingDescriptionApproved: (
      state: initialStateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.modernWorkFeature.MarketingDescriptionApproved = action.payload;
    },
    changeTiageTagId: (
      state: initialStateProps,
      action: { payload: number[] | null; type: string }
    ) => {
      state.modernWorkFeature.TriageTagIds = action.payload;
    },
    changePublicDisclosureDate: (
      state: initialStateProps,
      action: { payload: string | null; type: string }
    ) => {
      state.modernWorkFeature.PublicDisclosureDate = action.payload;
    },
    changeTriageDescription: (
      state: initialStateProps,
      action: { payload: string; type: string }
    ) => {
      state.modernWorkFeature.TriageDescription = action.payload;
    },
    changePMGContact: (
      state: initialStateProps,
      action: { payload: string; type: string }
    ) => {
      state.modernWorkFeature.PMGContact = action.payload;
    },
    changePMGContactEmail: (
      state: initialStateProps,
      action: { payload: string; type: string }
    ) => {
      state.modernWorkFeature.PMGContactEmail = action.payload;
    },
    changeIsFormValid: (
      state: initialStateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.modernWorkFeature.__isFormValid = action.payload;
    },
    changeLoading: (
      state: initialStateProps,
      action: { payload: string; type: string }
    ) => {
      state.modernWorkFeature.__isLoading = action.payload;
    },
    changeGADelayed: (
      state: initialStateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.modernWorkFeature.GADelayed = action.payload;
    },
    changePreviewDelayed: (
      state: initialStateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.modernWorkFeature.PreviewDelayed = action.payload;
    },
  },
  extraReducers: {
    [getFeature.fulfilled.type]: (state, { payload }) => {
      state.modernWorkFeature.Id = payload.Id;
      state.modernWorkFeature.Title = payload.Title;
      state.modernWorkFeature.SourceADOID = payload.SourceADOID;
      state.modernWorkFeature.RoadmapId = payload.RoadmapId;
      state.modernWorkFeature.PublicTitle = payload.PublicTitle;
      state.modernWorkFeature.Publicdescription = payload.Publicdescription;
      state.modernWorkFeature.PMGApproved = payload.PMGApproved;
      state.modernWorkFeature.Status = payload.Status;
      state.modernWorkFeature.PublicRoadmapStatus = payload.PublicRoadmapStatus;
      state.modernWorkFeature.PMGContact = payload.PMGContact;
      state.modernWorkFeature.PMGContactEmail = payload.PMGContactEmail;
      state.modernWorkFeature.PMContact = payload.PMContact;
      state.modernWorkFeature.PMContactEmail = payload.PMContactEmail;
      state.modernWorkFeature.Product = payload.Product;
      state.modernWorkFeature.CloudInstance = payload.CloudInstance;
      state.modernWorkFeature.CustomerRings = payload.CustomerRings;
      state.modernWorkFeature.Platform = payload.Platform;
      state.modernWorkFeature.MoreInfoLink = payload.MoreInfoLink;
      state.modernWorkFeature.PublicRoadmap = payload.PublicRoadmap;
      state.modernWorkFeature.ClientOrService = payload.ClientOrService;
      state.modernWorkFeature.MSETStags = payload.MSETStags;
      state.modernWorkFeature.PreviewDate = payload.PreviewDate;
      state.modernWorkFeature.GADate = payload.GADate;
      state.modernWorkFeature.GADelayed = payload.GADelayed;
      state.modernWorkFeature.PreviewDelayed = payload.PreviewDelayed;
      state.modernWorkFeature.LaunchDate = payload.LaunchDate;
      state.modernWorkFeature.DatePublishedToPublicRoadmap =
        payload.DatePublishedToPublicRoadmap;
      state.modernWorkFeature.LastModifiedOnPublicRoadmap =
        payload.LastModifiedOnPublicRoadmap;
      //DisclosureDate ?: string;
      //LastDisclosureDate ?: string;
      state.modernWorkFeature.CreatedBy = payload.CreatedBy;
      state.modernWorkFeature.CreatedByEmail = payload.CreatedByEmail;
      state.modernWorkFeature.Created = payload.Created;
      state.modernWorkFeature.ModifiedBy = payload.ModifiedBy;
      state.modernWorkFeature.ModifiedByEmail = payload.ModifiedByEmail;
      state.modernWorkFeature.Modified = payload.Modified;
      state.modernWorkFeature.IsActive = payload.IsActive;
      state.modernWorkFeature.IsDelayed = payload.IsDelayed;
      state.modernWorkFeature.TriageTagId = payload.TriageTagId;
      state.modernWorkFeature.TriageTagIds = payload.TriageTagIds;
      state.modernWorkFeature.TierId = payload.TierId;
      //Tier: string;
      state.modernWorkFeature.TriageDescription = payload.TriageDescription;
      //FeatureLink: string;
      state.modernWorkFeature.ModernWorkSecurityEventCalendarMomentsId =
        payload.ModernWorkSecurityEventCalendarMomentsId;
      state.modernWorkFeature.ModernWorkSecurityEventCalendarMomentsTitle =
        payload.ModernWorkSecurityEventCalendarMomentsTitle;
      state.modernWorkFeature.SecondModernWorkSecurityEventCalendarMomentsId =
        payload.SecondModernWorkSecurityEventCalendarMomentsId;
      state.modernWorkFeature.SecondModernWorkSecurityEventCalendarMomentsTitle =
        payload.SecondModernWorkSecurityEventCalendarMomentsTitle;
      state.modernWorkFeature.PublicDisclosureDate =
        payload.PublicDisclosureDate;
      state.modernWorkFeature.PlannedProductionDate =
        payload.PlannedProductionDate;
      state.modernWorkFeature.ServiceTargetDateDoD =
        payload.ServiceTargetDateDoD;
      state.modernWorkFeature.ServiceTargetDateGCC =
        payload.ServiceTargetDateGCC;
      state.modernWorkFeature.ServiceTargetDateGCCH =
        payload.ServiceTargetDateGCCH;
      state.modernWorkFeature.MarketingDescription =
        payload.MarketingDescription;
      state.modernWorkFeature.FeatureTheme = payload.FeatureTheme;
      state.modernWorkFeature.MarketingTitle = payload.MarketingTitle;
      state.modernWorkFeature.MarketingTitleApproved =
        payload.MarketingTitleApproved;
      state.modernWorkFeature.MarketingDescriptionApproved =
        payload.MarketingDescriptionApproved;
      //ModernWOrkFeatureProductAreaName: string;
      state.modernWorkFeature.ModernWorkFeatureProductAreaId =
        payload.ModernWorkFeatureProductAreaId;
      state.modernWorkFeature.FinalTitle = payload.FinalTitle;
      state.modernWorkFeature.FinalDescription = payload.FinalDescription;
      state.modernWorkFeature.CCHFeatureId = payload.CCHFeatureId;
      let centerId = (payload.MessageCenterId as string)
        .replaceAll("MC", "")
        .replaceAll(",", ", ");
      state.modernWorkFeature.MessageCenterId = centerId;
      state.modernWorkFeature.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [getFeature.pending.type]: (state: initialStateProps) => {
      state.modernWorkFeature.__isLoading = _LOADINGSTATE.pending;
    },
    [getFeature.rejected.type]: (state, reason) => {
      state.modernWorkFeature.__isLoading = _LOADINGSTATE.rejected;
      if (reason.error.message === "Request failed with status code 404") {
        ErrorMessage.show(
          "The request is not recognized. Please refresh the page or restart your browser."
        );
      } else {
        ErrorMessage.show(
          "There was an error getting the feature. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      }
    },
    [updateFeature.fulfilled.type]: (state: initialStateProps) => {
      state.modernWorkFeature.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [updateFeature.pending.type]: (state: initialStateProps) => {
      state.modernWorkFeature.__isLoading = _LOADINGSTATE.pending;
    },
    [updateFeature.rejected.type]: (state: initialStateProps, { error }) => {
      state.modernWorkFeature.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error updating the feature. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = modernWorkFeatureSlice;
