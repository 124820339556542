import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { isVoid, toExcel } from "../../../utils/utils";
import "../../Shared/css/timelineNavBar.scss";
import "./index.scss";
import powerbiicon from "./PowerBIIcon.png";
import copiloticon from "./CopilotIcon.png";
import {
  DirectionalHint,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TooltipHost,
} from "@fluentui/react";
import {
  ContentShowMode,
  TimelineMode,
  TimelineType,
  _LOADINGSTATE,
} from "../../Shared/constants";
import { buttonStyles } from "../../Shared/styleProps";
import DatePickerWithCustomStyles from "../../Shared/FormInput/datepickerWithCustomStyles";
import { RootState, useAppDispatch } from "../../../store";
import * as azureTimelineReducer from "../../../slice/azureTimeline";
import * as customTrainDatesReducer from "../../../slice/customTrainDate";
import * as calendarReducer from "../../../slice/calendar";
import { MilestoneModalContext } from "..";

export const MilestoneStatusIcon = [
  <span></span>,
  <span></span>,
  <span></span>,
  <span></span>,
  <i className="fa fa-circle" aria-hidden="true" aria-label="No action"></i>,
  <i
    className="ms-Icon root-43 "
    aria-hidden="true"
    data-icon-name="StatusTriangleOuter"
    aria-label="Action needed"
  >
    
  </i>,
];

const styles = mergeStyleSets({
  root: {
    maxWidth: 300,
    selectors: {
      "> *": { marginBottom: 15 },
      "> table, th, td": { border: 0, backgroundColor: "white" },
    },
  },
  button: { marginRight: 10 },
  input: { width: 120 },
});

interface Props {
  isStickyHead?: boolean | undefined;
  showPowerBIComponent?: boolean | false;
  setIsCopilotOpen?: (isOpen: boolean) => void;
}

const NavBar = (props: Props) => {
  const milestoneModalContext = useContext(MilestoneModalContext);

  const appDispatch = useAppDispatch();

  const customTrainDates = useSelector(
    (state: RootState) => state.customTrainDateReducer.CustomTrainDateList
  );
  const customTrainDatesForCalendar = useSelector(
    (state: RootState) => state.calendarReducer.CustomTrainDates
  );
  const microsoftHoliday = useSelector(
    (state: RootState) => state.calendarReducer.MicrosoftHolidays
  );
  const moments = useSelector(
    (state: RootState) => state.calendarReducer.Moments
  );
  const azureUpdateDeckDueDates = useSelector(
    (state: RootState) => state.calendarReducer.AzureUpdatesDeckDueDates
  );
  const calendarDates = useSelector(
    (state: RootState) => state.calendarReducer.CalendarDates
  );

  useEffect(() => {
    if (props.isStickyHead === false || props.isStickyHead === undefined) {
      if (
        customTrainDatesForCalendar.length === 0 ||
        microsoftHoliday.length === 0 ||
        moments.length === 0 ||
        azureUpdateDeckDueDates.length === 0 ||
        calendarDates.length == 0
      ) {
        appDispatch(calendarReducer.getCalendarDates(TimelineType.Azure));
      }
      if (customTrainDates.length === 0) {
        appDispatch(customTrainDatesReducer.getCustomTrainList());
      }
    }
  }, [appDispatch, props.isStickyHead]);

  const endMsg = isVoid(milestoneModalContext.appearEndDate)
    ? "end date is required"
    : milestoneModalContext.appearStartDate >
      milestoneModalContext.appearEndDate
    ? "End date must be after Start date for proper display"
    : "";

  const isMonthLoading = useSelector(
    (state: RootState) => state.azureTimelineReducer.isMonthLoading
  );

  const getData = (
    startDate: Date,
    endDate: Date | undefined,
    timelineMode: TimelineMode
  ) => {
    let appearStartDate = startDate || milestoneModalContext.appearStartDate;
    if (isMonthLoading === _LOADINGSTATE.pending) {
      return;
    }
    if (isVoid(milestoneModalContext.appearStartDate)) {
      return;
    }
    if (isVoid(milestoneModalContext.appearEndDate)) {
      return;
    }
    if (
      milestoneModalContext.appearStartDate >
      milestoneModalContext.appearEndDate
    ) {
      return;
    }

    milestoneModalContext.setSearchPhrase("");
    milestoneModalContext.setContentShowMode(ContentShowMode.Grid);
    if (timelineMode === TimelineMode.Timeline) {
      if (milestoneModalContext.timelineMode !== TimelineMode.Timeline) {
        milestoneModalContext.setTimelineMode(TimelineMode.Timeline);
        appDispatch(azureTimelineReducer.initAzureTimelineDataSet()).then(
          () => {
            milestoneModalContext.setStartDate(appearStartDate);
          }
        );
      } else {
        milestoneModalContext.setStartDate(appearStartDate);
      }
    } else {
      if (milestoneModalContext.timelineMode !== TimelineMode.TrainDate) {
        milestoneModalContext.setTimelineMode(TimelineMode.TrainDate);
      }
      if (endDate !== undefined) {
        appDispatch(
          azureTimelineReducer.actions.setTrainDataSet({
            startDate: startDate,
            endDate: endDate,
          })
        );
      }
      appDispatch(azureTimelineReducer.getDataSet(startDate, endDate));
    }
  };

  const isLoadingExportExcelMilestone = useSelector(
    (state: RootState) =>
      state.azureTimelineReducer.isLoadingExportExcelMilestone
  );

  const isLoadedExportExcelMilestone = useSelector(
    (state: RootState) =>
      state.azureTimelineReducer.isLoadedExportExcelMilestone
  );

  const isExportingExcelMilestone = useSelector(
    (state: RootState) => state.azureTimelineReducer.isExportingExcelMilestone
  );

  const handleDownload = () => {
    if (isExportingExcelMilestone === true) {
      return;
    }
    if (milestoneModalContext.timelineMode == TimelineMode.Search) {
      appDispatch(
        azureTimelineReducer.actions.setIsExportingExcelMilestone(true)
      );

      if (isLoadedExportExcelMilestone) {
        excelDownloadData();
      } else {
        appDispatch(
          azureTimelineReducer.actions.setIsLoadingExportExcelMilestone(true)
        );
        appDispatch(
          azureTimelineReducer.getTimelineExcelDownloadSearchMilestone(
            milestoneModalContext.searchPhrase
          )
        );
      }
    } else {
      if (isVoid(milestoneModalContext.appearStartDate)) {
        return;
      }
      if (isVoid(milestoneModalContext.appearEndDate)) {
        return;
      }
      if (
        milestoneModalContext.appearStartDate >
        milestoneModalContext.appearEndDate
      ) {
        return;
      }
      appDispatch(
        azureTimelineReducer.actions.setIsExportingExcelMilestone(true)
      );

      if (isLoadedExportExcelMilestone) {
        excelDownloadData();
      } else {
        appDispatch(
          azureTimelineReducer.actions.setIsLoadingExportExcelMilestone(true)
        );
        appDispatch(
          azureTimelineReducer.getExcelDownloadMilestoneDataSet(
            milestoneModalContext.appearStartDate,
            milestoneModalContext.appearEndDate
          )
        );
      }
    }
    return false;
  };

  const excelDownloadData = () => {
    const table = document.getElementById(
      "timeline-table-excel-download"
    )?.outerHTML;
    const result = table || "";
    if (result !== "") {
      toExcel(`azure-timeline-excel`, result);
    }

    appDispatch(
      azureTimelineReducer.actions.setIsExportingExcelMilestone(false)
    );
  };

  useEffect(() => {
    if (
      (props.isStickyHead === false || props.isStickyHead === undefined) &&
      isExportingExcelMilestone === true &&
      isLoadingExportExcelMilestone === false &&
      isLoadedExportExcelMilestone === true
    ) {
      excelDownloadData();
    }
  }, [
    isExportingExcelMilestone,
    isLoadingExportExcelMilestone,
    isLoadedExportExcelMilestone,
    props.isStickyHead,
    excelDownloadData,
  ]);

  useEffect(() => {
    appDispatch(
      azureTimelineReducer.actions.setIsLoadedExportExcelMilestone(false)
    );
  }, [
    appDispatch,
    milestoneModalContext.appearStartDate,
    milestoneModalContext.appearEndDate,
    milestoneModalContext.searchPhrase,
  ]);

  return (
    <div className="nav-container azuretimeline-nav-container">
      <div className="row container-navbar">
        <div className="col-3 d-flex justify-content-center section-wrap p-0">
          <div className="w-40">
            <div>
              <strong>Start date</strong>
            </div>
            <div className="startDatePicker">
              <DatePickerWithCustomStyles
                value={milestoneModalContext.appearStartDate}
                className={styles.input}
                placeholder="Select a date..."
                ariaLabel="Select a start date"
                onChangeHandler={(date: Date | null | undefined) => {
                  if (date && date != null) {
                    milestoneModalContext.setAppearStartDate(date);
                  }
                }}
              />
            </div>
            <span className="date-error">
              {!isVoid(milestoneModalContext.appearStartDate)
                ? ""
                : "begin date is required"}
            </span>
          </div>
          <div>
            <div>
              <label></label>
            </div>
            <div>&nbsp; - &nbsp;</div>
          </div>
          <div className="w-40">
            <div>
              <strong>End date</strong>
            </div>
            <div className="endDatePicker">
              <DatePickerWithCustomStyles
                value={milestoneModalContext.appearEndDate}
                className={styles.input}
                placeholder="Select a date..."
                ariaLabel="Select a end date"
                onChangeHandler={(date: Date | null | undefined) => {
                  if (date && date != null) {
                    milestoneModalContext.setAppearEndDate(date);
                  }
                }}
              />
            </div>
            <span
              style={{ fontSize: "13px", position: "absolute" }}
              className="date-error"
            >
              {endMsg}
            </span>
          </div>
          <div>
            <div>
              <label></label>
            </div>
            &nbsp;&nbsp;
            <PrimaryButton
              text="Go"
              allowDisabledFocus
              style={{ minWidth: "50px" }}
              onClick={() => {
                getData(
                  milestoneModalContext.appearStartDate,
                  undefined,
                  TimelineMode.Timeline
                );
              }}
              styles={buttonStyles}
            />
          </div>
        </div>
        <div className="col-1 section-wrap text-center export-to">
          <div>
            <strong>Export to:</strong>
          </div>
          <div>
            <a
              href="#!"
              aria-label="Excel, Export to Excel"
              onClick={handleDownload}
              role="button"
            >
              <span style={{ lineHeight: "32px" }}>Excel </span>
              {isExportingExcelMilestone ? (
                <Spinner size={SpinnerSize.small} ariaLive="polite" />
              ) : null}
            </a>
          </div>
        </div>
        <div className="col-3 section-wrap">
          <div>
            {" "}
            <div>
              <strong>Milestone type key:</strong>
            </div>
          </div>
          <div className="milestone-legends vertical-align mt-2">
            <div className="row-align">
              <div
                className="legend-item legend-item-disclosure"
                style={{ marginLeft: 0 }}
              ></div>
              <div className="legend-item-title" title="Disclosure only">
                Disclosure only
              </div>
              <div className="legend-item legend-item-ga"></div>
              <div className="legend-item-title" title="GA">
                GA
              </div>
              <div className="legend-item legend-item-ga-retired"></div>
              <div className="legend-item-title" title="GA Retired">
                GA Retired
              </div>
              <div className="legend-item legend-item-rtm"></div>
              <div className="legend-item-title" title="RTM">
                RTM
              </div>
            </div>
            <div className="row-align">
              <div
                className="legend-item legend-item-preret"
                style={{ marginLeft: 0 }}
              ></div>
              <div className="legend-item-title" title="Preview Retired">
                Preview Retired
              </div>
              <div className="legend-item legend-item-priprev"></div>
              <div className="legend-item-title" title="Private Preview">
                Private Preview
              </div>
              <div className="legend-item legend-item-pubprev"></div>
              <div className="legend-item-title" title="Public Preview">
                Public Preview
              </div>
            </div>
          </div>
        </div>
        <div className="col section-wrap">
          <div>
            {" "}
            <div>
              <strong>Planning:</strong>
            </div>
          </div>
          <div className="dashboard-legend row-align mt-2">
            <div style={{ marginLeft: 0 }}>
              <i
                className="fa fa-circle"
                aria-hidden="true"
                aria-label="No action"
              ></i>
              <span className="ml-5" title="No action">
                No action
              </span>
            </div>
            <div className="d-flex justify-content-between ml-10">
              <span>
                <i
                  className="ms-Icon root-43 "
                  data-icon-name="StatusTriangleOuter"
                  aria-hidden="true"
                  aria-label="Action needed"
                >
                  
                </i>
              </span>
              <span className="ml-5" title="Action needed">
                Action needed
              </span>
            </div>
          </div>
        </div>
        <div className="col section-wrap">
          <br />
          <div
            className="dashboard-legend"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              height: "50%",
              width: "100%",
              gap: "7px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <span>
                <i
                  className="ms-Icon root-43 "
                  data-icon-name="FavoriteStarFill"
                  aria-hidden="true"
                  aria-label="Second Moment"
                >
                  
                </i>
              </span>
              <span title="Second Moment">Second Moment</span>
            </div>
          </div>
        </div>
        <div
          className="col-1"
          style={{ display: "flex", alignItems: "center", marginRight: "88px" }}
        >
          {milestoneModalContext.timelineMode == TimelineMode.Search ? (
            ""
          ) : (
            <TooltipHost
              content="Grid Mode"
              styles={{ root: { display: "inline-block" } }}
              directionalHint={DirectionalHint.topCenter}
            >
              <i
                className="ms-Icon ms-Icon--GridViewMedium"
                style={
                  milestoneModalContext.contentShowMode === ContentShowMode.Grid
                    ? {
                        borderStyle: "solid",
                        borderWidth: "medium",
                        cursor: "pointer",
                        fontSize: "35px",
                      }
                    : { cursor: "pointer", fontSize: "35px" }
                }
                onClick={() => {
                  milestoneModalContext.setContentShowMode(
                    ContentShowMode.Grid
                  );
                }}
                alt-text="Grid Mode"
                aria-label="Grid Mode"
                role="button"
                aria-pressed={
                  milestoneModalContext.contentShowMode === ContentShowMode.Grid
                }
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.currentTarget.click();
                  }
                }}
              ></i>
            </TooltipHost>
          )}
          <TooltipHost
            content="Column Mode"
            directionalHint={DirectionalHint.topCenter}
            styles={{ root: { display: "inline-block" } }}
          >
            <i
              className="ms-Icon ms-Icon--TripleColumn"
              style={
                milestoneModalContext.contentShowMode === ContentShowMode.Column
                  ? {
                      borderStyle: "solid",
                      borderWidth: "medium",
                      cursor: "pointer",
                      fontSize: "35px",
                      marginLeft: "12px",
                      transform: "rotate(90deg)",
                    }
                  : {
                      cursor: "pointer",
                      fontSize: "35px",
                      margin: "0 0 0 25px",
                      transform: "rotate(90deg)",
                    }
              }
              onClick={() => {
                milestoneModalContext.setContentShowMode(
                  ContentShowMode.Column
                );
              }}
              alt-text="Column Mode"
              aria-label="Column Mode"
              role="button"
              aria-pressed={
                milestoneModalContext.contentShowMode === ContentShowMode.Column
              }
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.currentTarget.click();
                }
              }}
            ></i>
          </TooltipHost>
          {props.showPowerBIComponent && (
            <TooltipHost
              content="Dashboard Mode"
              directionalHint={DirectionalHint.topCenter}
              styles={{ root: { display: "inline-block" } }}
            >
              <img
                src={powerbiicon}
                style={
                  milestoneModalContext.contentShowMode ===
                  ContentShowMode.Dashboard
                    ? {
                        fontSize: "35px",
                        cursor: "pointer",
                        borderStyle: "solid",
                        borderWidth: "medium",
                        marginLeft: "20px",
                        height: "35px",
                        width: "35px",
                      }
                    : {
                        fontSize: "35px",
                        cursor: "pointer",
                        marginLeft: "20px",
                        height: "35px",
                        width: "35px",
                        border: "solid",
                        color: "#D5D4D3",
                        borderWidth: "2px",
                        borderRadius: "6px",
                        padding: "2px",
                      }
                }
                onClick={() => {
                  milestoneModalContext.setContentShowMode(
                    ContentShowMode.Dashboard
                  );
                }}
                alt-text="Dashboard Mode"
                aria-label="Dashboard Mode"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.currentTarget.click();
                  }
                }}
              />
            </TooltipHost>
          )}

          <TooltipHost
            content="Copilot Mode"
            directionalHint={DirectionalHint.topCenter}
            styles={{ root: { display: "inline-block" } }}
          >
            <img
              src={copiloticon}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginLeft: "20px",
                height: "35px",
                width: "35px",
                border: "solid",
                color: "#D5D4D3",
                borderWidth: "2px",
                borderRadius: "6px",
                padding: "2px",
              }}
              onClick={() => {
                props.setIsCopilotOpen?.(true);
              }}
              alt-text="Copilot Mode"
              aria-label="Copilot Mode"
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.currentTarget.click();
                }
              }}
            />
          </TooltipHost>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
