import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { convertDateTimeToFormatedDateString } from "../utils/date";
import { _LOADINGSTATE } from "../components/Shared/constants";
import { ErrorMessage } from "../components/Shared/messageBox";

import milestoneService from "../services/milestone";

export interface Milestone {
  MilestoneId: number;
  ProductId: number;
  ContainerId?: number;
  RoadmapMilestoneId: number | null;
  DisclosureMilestoneId: number | null;
  ContactName: string;
  MilestoneTypeId: number;
  MilestoneTypeName: string;
  MilestoneDescription: string;
  MarketingLeadName: string;
  EngineeringContactName: string;
  MilestoneName: string;
  RoadmapStartDate: string | null;
  DisclosureStartDate: string | null;
  DisclosureEndDate: string | null;
  IsDeleted: boolean;
  CustomEventDateId: number | null;
  TierId: number;
  Tier: string;
  CreatedDate: string;
  CreatedBy: string;
  ModifiedDate: string;
  ModifiedBy: string;
  IsNotShare: boolean;
  IsSelectedAudiencesChanged: boolean;
  SelectedAudiences: string;
  MilestoneAudiencesName: string;
  MilestoneLink: string;
  CloseDownDate: string | null;
  FeatureSKUService: number;
  Answer: string;
  Status: number;
  Notes: string;
  ModernWorkSecurityEventCalendarMomentsId: number | null;
  MomentTitle: string;
  SecondModernWorkSecurityEventCalendarMomentsId: number | null;
  SecondMomentTitle: string;
  FDA: number | null;
  FAQ: number | null;
  AzureUpdates: number | null;
  AzureUpdatesDeck: number | null;
  TrainContentLastUpdated: string;
  TrainContentLastUpdatedBy: string;
  __isFormValid: boolean;
  __isLoading: string;
}

export interface milestoneGroupByTier {
  tier: string;
  milestones: Milestone[];
}

interface initialSateProps {
  SingleMilestone: Milestone;
  isMilestoneMonthsLoading: string;
  milestoneMonths: string[];
}

export const initialMilestoneState: Milestone = {
  MilestoneId: 0,
  ProductId: 0,
  ContainerId: 0,
  RoadmapMilestoneId: 0,
  DisclosureMilestoneId: 0,
  MilestoneTypeId: 0,
  MilestoneName: "",
  MilestoneTypeName: "",
  MilestoneDescription: "",
  ContactName: "",
  MarketingLeadName: "",
  EngineeringContactName: "",
  RoadmapStartDate: new Date(
    convertDateTimeToFormatedDateString(new Date())
  ).toISOString(),
  DisclosureStartDate: new Date(
    convertDateTimeToFormatedDateString(new Date())
  ).toISOString(),
  DisclosureEndDate: new Date(
    convertDateTimeToFormatedDateString(new Date())
  ).toISOString(),
  IsDeleted: false,
  CustomEventDateId: null,
  TierId: 0,
  Tier: "",
  CreatedDate: new Date().toISOString(),
  CreatedBy: "",
  ModifiedDate: new Date().toISOString(),
  ModifiedBy: "",
  IsNotShare: false,
  IsSelectedAudiencesChanged: false,
  SelectedAudiences: "[]",
  MilestoneAudiencesName: "",
  MilestoneLink: "",
  CloseDownDate: new Date(
    convertDateTimeToFormatedDateString(new Date())
  ).toISOString(),
  FeatureSKUService: 0,
  Answer: "",
  Status: 0,
  Notes: "",
  ModernWorkSecurityEventCalendarMomentsId: null,
  MomentTitle: "",
  SecondModernWorkSecurityEventCalendarMomentsId: null,
  SecondMomentTitle: "",
  FDA: null,
  FAQ: null,
  AzureUpdates: null,
  AzureUpdatesDeck: null,
  TrainContentLastUpdated: "",
  TrainContentLastUpdatedBy: "",
  __isFormValid: false,
  __isLoading: "",
};

const initialState: initialSateProps = {
  SingleMilestone: initialMilestoneState,
  isMilestoneMonthsLoading: _LOADINGSTATE.pending,
  milestoneMonths: [],
};

let timer: number;
export const debounceChange = (action: any, delay: number) => {
  return (dispatch: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(action);
    }, delay) as unknown as number;
  };
};

export const getMilestone = createAsyncThunk(
  "getMilestone",
  async (id: number, { dispatch, getState }) => {
    let milestone = (await milestoneService.getMilestone(id)) as Milestone;
    return milestone;
  }
);

export const getMilestoneByMomentId = createAsyncThunk(
  "getMilestoneByMomentId",
  async (id: number, { dispatch, getState }) => {
    return await milestoneService.getMilestoneByMomentId(id);
  }
);

export const updateMilestone = createAsyncThunk(
  "updateMilestone",
  async (
    params: { Id: number; Milestone: Milestone },
    { dispatch, getState }
  ) => {
    return (await milestoneService.updateMilestone(
      params.Milestone
    )) as Milestone;
  }
);

export const updateMilestoneStatus = createAsyncThunk(
  "updateMilestoneStatus",
  async (
    Milestone: {
      MilestoneId: number;
      RoadmapMilestoneId: number;
      DisclosureMilestoneId: number;
      Status: number;
    },
    { dispatch, getState }
  ) => {
    dispatch(milestoneSlice.actions.changeStatus(Milestone.Status));
    return await milestoneService.updateMilestoneStatus(Milestone);
  }
);

export const updateMilestoneNotes = createAsyncThunk(
  "updateMilestoneNotes",
  async (
    Milestone: {
      MilestoneId: number;
      RoadmapMilestoneId: number;
      DisclosureMilestoneId: number;
      Notes: string;
    },
    { dispatch, getState }
  ) => {
    return await milestoneService.updateMilestoneNotes(Milestone);
  }
);

export const postMilestone = createAsyncThunk(
  "postMilestone",
  async (params: Milestone, { dispatch, getState }) => {
    return await milestoneService.saveMilestone(params);
  }
);

export const deleteMilestone = createAsyncThunk(
  "deleteMilestone",
  async (params: number) => {
    return await milestoneService.deleteMilestone(params);
  }
);

const milestoneSlice = createSlice({
  name: "milestone",
  initialState: initialState,
  reducers: {
    resetMilestoneId: (state: initialSateProps) => {
      state.SingleMilestone.MilestoneId = 0;
      state.SingleMilestone.DisclosureMilestoneId = 0;
      state.SingleMilestone.RoadmapMilestoneId = 0;
    },
    reset: (state: initialSateProps) => {
      state.SingleMilestone.MilestoneId = 0;
      state.SingleMilestone.ProductId = 0;
      state.SingleMilestone.ContainerId = 0;
      state.SingleMilestone.CustomEventDateId = null;
      state.SingleMilestone.TierId = 0;
      state.SingleMilestone.MilestoneName = "";
      state.SingleMilestone.MilestoneDescription = "";
      state.SingleMilestone.ContactName = "";
      state.SingleMilestone.MarketingLeadName = "";
      state.SingleMilestone.EngineeringContactName = "";
      state.SingleMilestone.RoadmapStartDate = new Date(
        convertDateTimeToFormatedDateString(new Date())
      ).toISOString();
      state.SingleMilestone.DisclosureStartDate = new Date(
        convertDateTimeToFormatedDateString(new Date())
      ).toISOString();
      state.SingleMilestone.DisclosureEndDate = new Date(
        convertDateTimeToFormatedDateString(new Date())
      ).toISOString();
      state.SingleMilestone.CreatedDate = new Date().toISOString();
      state.SingleMilestone.CreatedBy = "";
      state.SingleMilestone.ModifiedDate = new Date().toISOString();
      state.SingleMilestone.ModifiedBy = "";
      state.SingleMilestone.MilestoneTypeId = 0;
      state.SingleMilestone.IsDeleted = false;
      state.SingleMilestone.Tier = "";
      state.SingleMilestone.IsNotShare = false;
      state.SingleMilestone.IsSelectedAudiencesChanged = false;
      state.SingleMilestone.DisclosureMilestoneId = 0;
      state.SingleMilestone.RoadmapMilestoneId = 0;
      state.SingleMilestone.MilestoneLink = "";
      state.SingleMilestone.SelectedAudiences = "[]";
      state.SingleMilestone.CloseDownDate = new Date(
        convertDateTimeToFormatedDateString(new Date())
      ).toISOString();
      state.SingleMilestone.FeatureSKUService = 0;
      state.SingleMilestone.Answer = "";
      state.SingleMilestone.Status = 0;
      state.SingleMilestone.Notes = "";
      state.SingleMilestone.ModernWorkSecurityEventCalendarMomentsId = null;
      state.SingleMilestone.SecondModernWorkSecurityEventCalendarMomentsId =
        null;
      state.SingleMilestone.__isFormValid = false;
    },
    changeMilestoneName: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.MilestoneName = action.payload;
    },
    changeMilestoneDescription: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.MilestoneDescription = action.payload;
    },
    changeMilestoneType: (
      state: initialSateProps,
      action: { payload: number; type: string }
    ) => {
      state.SingleMilestone.MilestoneTypeId = action.payload;
    },
    changeTier: (
      state: initialSateProps,
      action: { payload: number; type: string }
    ) => {
      state.SingleMilestone.TierId = action.payload;
    },
    changeEvent: (
      state: initialSateProps,
      action: { payload: number; type: string }
    ) => {
      state.SingleMilestone.CustomEventDateId = action.payload;
    },
    changeMoments: (
      state: initialSateProps,
      action: { payload: any[]; type: string }
    ) => {
      const payloadCopy = [...action.payload];
      state.SingleMilestone.ModernWorkSecurityEventCalendarMomentsId = null;
      state.SingleMilestone.SecondModernWorkSecurityEventCalendarMomentsId =
        null;
      if (payloadCopy.length > 0) {
        state.SingleMilestone.ModernWorkSecurityEventCalendarMomentsId =
          payloadCopy[0];
      }
      if (payloadCopy.length > 1) {
        state.SingleMilestone.SecondModernWorkSecurityEventCalendarMomentsId =
          payloadCopy[1];
      }
    },
    changeEngineeringContactName: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.EngineeringContactName = action.payload;
    },
    changeMarketingLeadName: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.MarketingLeadName = action.payload;
    },
    changeContactName: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.ContactName = action.payload;
    },
    changeIsFormValid: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.SingleMilestone.__isFormValid = action.payload;
    },
    changeSelectedAudiences: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.IsSelectedAudiencesChanged = true;
      state.SingleMilestone.SelectedAudiences = action.payload;
    },
    changeProductId: (
      state: initialSateProps,
      action: { payload: number; type: string }
    ) => {
      state.SingleMilestone.ProductId = action.payload;
    },
    changeCloseDownDate: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.CloseDownDate = action.payload;
    },
    changeFeatureSKUService: (
      state: initialSateProps,
      action: { payload: number; type: string }
    ) => {
      state.SingleMilestone.FeatureSKUService = action.payload;
    },
    changeAnswer: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.Answer = action.payload;
    },
    changeRoadmapStartDate: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.RoadmapStartDate = action.payload;
    },
    changeDisclosureStartDate: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.DisclosureStartDate = action.payload;
    },
    changeStatus: (
      state: initialSateProps,
      action: { payload: number; type: string }
    ) => {
      state.SingleMilestone.Status = action.payload;
    },
    changeIsNotShare: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.SingleMilestone.IsNotShare = action.payload;
    },
    changeNotes: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.Notes = action.payload;
    },
    changeLoading: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.__isLoading = action.payload;
    },
  },
  extraReducers: {
    [getMilestone.pending.type]: (state: initialSateProps) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.pending;
    },
    [getMilestone.fulfilled.type]: (
      state: initialSateProps,
      action: PayloadAction<Milestone>
    ) => {
      if (action.payload.RoadmapMilestoneId === null) {
        ErrorMessage.show(
          "Please email <a href='mailto:cmroadmap@microsoft.com'>cmroadmap@microsoft.com</a> for archived data"
        );
        throw new Error();
      } else {
        state.SingleMilestone.MilestoneId = action.payload.MilestoneId;
        state.SingleMilestone.ProductId = action.payload.ProductId;
        state.SingleMilestone.ContainerId = action.payload.ContainerId;
        state.SingleMilestone.CustomEventDateId =
          action.payload.CustomEventDateId;
        state.SingleMilestone.TierId = action.payload.TierId;
        state.SingleMilestone.MilestoneName = action.payload.MilestoneName;
        state.SingleMilestone.MilestoneDescription =
          action.payload.MilestoneDescription;
        state.SingleMilestone.ContactName = action.payload.ContactName;
        state.SingleMilestone.MarketingLeadName =
          action.payload.MarketingLeadName;
        state.SingleMilestone.EngineeringContactName =
          action.payload.EngineeringContactName;
        state.SingleMilestone.RoadmapStartDate =
          action.payload.RoadmapStartDate;
        state.SingleMilestone.DisclosureStartDate =
          action.payload.DisclosureStartDate;
        state.SingleMilestone.DisclosureEndDate =
          action.payload.DisclosureEndDate;
        state.SingleMilestone.CreatedDate = action.payload.CreatedDate;
        state.SingleMilestone.CreatedBy = action.payload.CreatedBy;
        state.SingleMilestone.ModifiedDate = action.payload.ModifiedDate;
        state.SingleMilestone.ModifiedBy = action.payload.ModifiedBy;
        state.SingleMilestone.MilestoneTypeId = action.payload.MilestoneTypeId;
        state.SingleMilestone.IsDeleted = action.payload.IsDeleted;
        state.SingleMilestone.Tier = action.payload.Tier;
        state.SingleMilestone.IsNotShare = action.payload.IsNotShare;
        state.SingleMilestone.IsSelectedAudiencesChanged =
          action.payload.IsSelectedAudiencesChanged;
        state.SingleMilestone.DisclosureMilestoneId =
          action.payload.DisclosureMilestoneId;
        state.SingleMilestone.RoadmapMilestoneId =
          action.payload.RoadmapMilestoneId;
        state.SingleMilestone.MilestoneLink = action.payload.MilestoneLink;
        state.SingleMilestone.SelectedAudiences =
          action.payload.SelectedAudiences;
        state.SingleMilestone.CloseDownDate = action.payload.CloseDownDate;
        state.SingleMilestone.FeatureSKUService =
          action.payload.FeatureSKUService;
        state.SingleMilestone.Answer = action.payload.Answer;
        state.SingleMilestone.Status = action.payload.Status;
        state.SingleMilestone.Notes = action.payload.Notes || "";
        state.SingleMilestone.ModernWorkSecurityEventCalendarMomentsId =
          action.payload.ModernWorkSecurityEventCalendarMomentsId;
        state.SingleMilestone.SecondModernWorkSecurityEventCalendarMomentsId =
          action.payload.SecondModernWorkSecurityEventCalendarMomentsId;
      }
      state.SingleMilestone.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [getMilestone.rejected.type]: (state, reason) => {
      if (reason.error.message === "Request failed with status code 404") {
        ErrorMessage.show(
          "The request is not recognized. Please refresh the page or restart your browser."
        );
      } else {
        ErrorMessage.show(
          "There was an error getting the milestone. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      }
    },
    [updateMilestone.fulfilled.type]: (
      state: initialSateProps,
      { payload }
    ) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [updateMilestone.pending.type]: (state: initialSateProps) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.pending;
    },
    [updateMilestone.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error updating the milestone. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [postMilestone.fulfilled.type]: (state: initialSateProps, { payload }) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [postMilestone.pending.type]: (state: initialSateProps) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.pending;
    },
    [postMilestone.rejected.type]: (state, { error }) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error posting the milestone. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [updateMilestoneStatus.fulfilled.type]: (state) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [updateMilestoneStatus.pending.type]: (state) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.pending;
    },
    [updateMilestoneStatus.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error updating the milestone status. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [updateMilestoneNotes.fulfilled.type]: (state) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [updateMilestoneNotes.pending.type]: (state) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.pending;
    },
    [updateMilestoneNotes.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error updating the milestone notes. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = milestoneSlice;
