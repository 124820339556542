import { createSlice } from "@reduxjs/toolkit";
import { IDropdownOption } from "@fluentui/react";
import { ConstantItems } from "../../components/Calendar/Filter/common";
export interface CalendarMarketingField {
  DateSelection: IDropdownOption[];
  SolutionArea: IDropdownOption[];
  ProductFamily: IDropdownOption[];
  MomentType: IDropdownOption[];
  PRSupport: IDropdownOption[];
  MainDisclosureChannel: IDropdownOption[];
  TargetAudience: IDropdownOption[];
  TBCCategory: IDropdownOption[];
  TBCYearSelection: IDropdownOption[];
  TBCMonthSelection: IDropdownOption[];
  TBCFYYearSelection: IDropdownOption[];
  TBCQuarterSelection: IDropdownOption[];
  Tier: IDropdownOption[];
}

const InitCalendarMarketingField: CalendarMarketingField = {
  DateSelection: ConstantItems.dateSelection.map((p) => {
    return { key: p, text: p };
  }),
  SolutionArea: ConstantItems.solutionArea.map((p) => {
    return { key: p, text: p };
  }),
  ProductFamily: ConstantItems.productFamily.map((p) => {
    return { key: p, text: p };
  }),
  MomentType: ConstantItems.momentType.map((p) => {
    return { key: p, text: p };
  }),
  PRSupport: ConstantItems.prSupport.map((p) => {
    return { key: p === "Yes" ? 1 : 0, text: p };
  }),
  MainDisclosureChannel: ConstantItems.mainDisclosureChannels.map((p) => {
    return { key: p, text: p };
  }),
  TargetAudience: ConstantItems.targetAudiences.map((p) => {
    return { key: p, text: p };
  }),
  TBCCategory: ConstantItems.tbcCategory.map((p) => {
    return { key: p, text: p };
  }),
  TBCYearSelection: ConstantItems.tbcYearSelection,
  TBCMonthSelection: ConstantItems.tbcMonthSelection,
  TBCFYYearSelection: ConstantItems.tbcFYYearSelection,
  TBCQuarterSelection: ConstantItems.tbcQuarterSelection,
  Tier: ConstantItems.tier.map((p) => {
    return { key: p, text: p };
  }),
};

const calendarFieldOptionSlice = createSlice({
  name: "calendarFieldOptionSlice",
  initialState: InitCalendarMarketingField,
  reducers: {},
});

export const { reducer, actions } = calendarFieldOptionSlice;
