import { useContext, useState, useRef } from "react";
import {
  Dialog,
  IIconProps,
  ContextualMenu,
  DialogType,
  IDialogContentProps,
  Panel,
  PanelType,
  DefaultButton,
  DialogFooter,
  IPanel,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
//@ts-ignore
import { useSelector } from "react-redux";
import moment from "moment";
import { RootState, useAppDispatch } from "../../../store";
import * as CalendarUpdateReducer from "../../../slice/CalendarSlices/calendarUpdate";
import { CalendarContext } from "../context";
import MomentAssociationDetail from "./momentAssociationDetail";
import { _AUTHORIZATIONTYPE } from "../../Shared/constants";
import { AppContext } from "../../../App";

const MomentDetail = (props: {}) => {
  const appContext = useContext(AppContext);
  const data = useContext(CalendarContext);
  const appDispatch = useAppDispatch();

  var updatereducer = useSelector(
    (state: RootState) => state.calendarUpdateReducer
  );
  const currentMoment = updatereducer.currentMoment;
  const detailPanelOpen = updatereducer.detailPanelOpen;

  const fieldStyle = {
    marginLeft: "10px",
    borderStyle: "hidden",
    fontSize: "13px",
  };
  const editIcon: IIconProps = { iconName: "Edit" };
  const DeleteIcon: IIconProps = { iconName: "Delete" };
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [isFooterEnable, setFooterEnable] = useBoolean(false);

  let editReadOnly = true;
  if (
    appContext.accessType === _AUTHORIZATIONTYPE.admin ||
    appContext.accessType === _AUTHORIZATIONTYPE.dri
  ) {
    editReadOnly = false;
  }

  let deleteReadOnly = true;
  if (appContext.accessType === _AUTHORIZATIONTYPE.admin) {
    deleteReadOnly = false;
  }

  const [editReadOnlyButton, setEditReadOnlyButton] = useState(editReadOnly);

  const [deleteReadOnlyButton, setDeleteReadOnlyButton] =
    useState(deleteReadOnly);

  const dialogContentProps: IDialogContentProps = {
    type: DialogType.normal,
    title: "Delete Moment",
    closeButtonAriaLabel: "Close",
    subText: "Are you sure you want to delete this event?",
    styles: {
      innerContent: {
        height: "45%",
        marginTop: "30px",
      },
      inner: {
        maxHeight: "176px !important",
      },
    },
  };
  const dialogStyles = { main: { maxWidth: 450, maxHeight: 450 } };
  const PanelRef = useRef<IPanel>(null);
  const dragOptions = {
    moveMenuItemText: "Move",
    closeMenuItemText: "Close",
    menu: ContextualMenu,
    keepInBounds: true,
  };
  const modalProps = {
    isBlocking: false,
    styles: dialogStyles,
    dragOptions: dragOptions,
  };
  return (
    <Panel
      isLightDismiss={true}
      isOpen={detailPanelOpen}
      isBlocking={true}
      componentRef={PanelRef}
      onDismiss={(e) => {
        appDispatch(CalendarUpdateReducer.actions.changeDetailPanelOpen(false));
      }}
      type={PanelType.medium}
      closeButtonAriaLabel="Close"
      onRenderHeader={(panelProps, defaultRender) => {
        return (
          <div style={{ width: "100%" }}>
            <DefaultButton
              disabled={editReadOnlyButton}
              style={{ borderStyle: "hidden" }}
              iconProps={editIcon}
              text={"Edit"}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                appDispatch(
                  CalendarUpdateReducer.actions.changeEditPanelState(
                    CalendarUpdateReducer.Status.Edit
                  )
                );
                appDispatch(
                  CalendarUpdateReducer.actions.changeEditPanelOpen(true)
                );
                appDispatch(
                  CalendarUpdateReducer.actions.changeDetailPanelOpen(false)
                );
                data.modalRef?.current?.click();
              }}
            />
            <DefaultButton
              disabled={deleteReadOnlyButton}
              style={{ borderStyle: "hidden" }}
              iconProps={DeleteIcon}
              text={"Delete"}
              onClick={() => {
                toggleHideDialog();
              }}
            />
          </div>
        );
      }}
    >
      <h4>{currentMoment.Title}</h4>
      <h6>Moment ID</h6>
      <span style={fieldStyle}>{currentMoment.Id}</span>
      <h6>Tier</h6>
      <span style={fieldStyle}>{currentMoment.MomentTier}</span>
      <h6>Start date</h6>
      <span style={fieldStyle}>
        {currentMoment.StartDate === null
          ? ""
          : moment(new Date(currentMoment.StartDate))
              .utc()
              .format("MMMM Do YYYY") + " (All day)"}
      </span>
      {currentMoment.DateSelection &&
      currentMoment.DateSelection != "Watch Day" ? (
        <>
          <h6>End date</h6>
          <span style={fieldStyle}>
            {currentMoment.EndDate === null
              ? ""
              : moment(new Date(currentMoment.EndDate))
                  .utc()
                  .format("MMMM Do YYYY") + " (All day)"}
          </span>
        </>
      ) : (
        <></>
      )}
      <h6>Solution area</h6>
      <span style={fieldStyle}>{currentMoment.SolutionArea.join(",")}</span>
      <h6>Product family</h6>
      <span style={fieldStyle}>{currentMoment.ProductFamily?.join(",")}</span>
      {currentMoment.MomentType &&
      currentMoment.MomentType.length !== 0 &&
      currentMoment.MomentType[0] !== "" ? (
        <>
          <h6>Moment type</h6>
          <span style={fieldStyle}>{currentMoment.MomentType.join(",")}</span>
        </>
      ) : (
        <></>
      )}
      {currentMoment.Contacts != null &&
      currentMoment.Contacts.length != 0 &&
      currentMoment.Contacts[0] !== "" ? (
        <>
          <h6>Contact(s)</h6>
          <span style={fieldStyle}>{currentMoment.Contacts.join(",")}</span>
        </>
      ) : undefined}
      <MomentAssociationDetail
        currentSelectedEventId={currentMoment.Id ?? null}
      />
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <DefaultButton
            disabled={isFooterEnable}
            onClick={() => {
              setFooterEnable.setTrue();
              if (currentMoment.Id) {
                appDispatch(
                  CalendarUpdateReducer.deleteMoment(currentMoment.Id)
                ).then(() => {
                  setFooterEnable.setFalse();
                  data.getModernWorkSecurityEventCalendarMomentList();
                  toggleHideDialog();
                  appDispatch(
                    CalendarUpdateReducer.actions.changeDetailPanelOpen(false)
                  );
                });
              }
            }}
            text="Delete"
          />
          <DefaultButton
            disabled={isFooterEnable}
            onClick={toggleHideDialog}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};

export default MomentDetail;
