import { useContext, useMemo } from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { useUrlQueryParam } from "../../../../utils/url";
import { TimelineMode, _LOADINGSTATE } from "../../../Shared/constants";
import { splitStringToArray } from "../../../../utils/string";
import { isVoid } from "../../../../utils/utils";
import { ModernWorkFeatureModalContext } from "../../index";
import { ProductFeature } from "./productFeature";
import {
  modernWorkTimelineFeature,
  DataSetProps,
} from "../../../../slice/modernWorkTimeline";
import { filter, featureFilterList } from "../../../../slice/savedDataFilters";
import {
  initialModernWorkFeatureState,
  ModernWorkFeature,
} from "../../../../slice/modernWorkFeature";

const RenderFeature = (props: { dataSet: Omit<DataSetProps, "moments">[] }) => {
  const [param] = useUrlQueryParam(
    featureFilterList.map((f: filter) => {
      return f.filterName;
    })
  );
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );

  let searchResult = 0;
  let resultText = "";
  const featureDataSets = useMemo(() => {
    let datas: DataSetProps[] = [];
    props.dataSet.forEach((dataSet: Omit<DataSetProps, "moments">) => {
      let filterFeatures: {
        productName: string;
        feature: modernWorkTimelineFeature[];
        featureExcelDownload: ModernWorkFeature[];
      }[] = [];

      if (dataSet.feature && dataSet.feature !== null) {
        dataSet.feature.features.forEach(
          (feature: {
            productName: string;
            feature: modernWorkTimelineFeature[];
            featureExcelDownload: ModernWorkFeature[];
          }) => {
            let features: modernWorkTimelineFeature[] = [];
            feature.feature.forEach((feature: modernWorkTimelineFeature) => {
              if (param.PMGContact) {
                if (
                  decodeURIComponent(param.PMGContact) !== feature.PMGContact
                ) {
                  return true;
                }
              }

              if (param.PMContact) {
                if (decodeURIComponent(param.PMContact) !== feature.PMContact) {
                  return true;
                }
              }

              if (param.Product) {
                if (param.Action === "And") {
                  let decodeUrlProduct = decodeURIComponent(param.Product);
                  if (feature.CCHFeatureId == 74387) {
                    console.log("feature", feature);
                  }
                  let converToModenWorkfeature: ModernWorkFeature =
                    feature as unknown as ModernWorkFeature;
                  if (converToModenWorkfeature.AllProducts === undefined) {
                    return true;
                  } else {
                    let products = decodeUrlProduct.split(";|;");
                    let findProduct = true;
                    products.forEach((p) => {
                      if (
                        converToModenWorkfeature.AllProducts?.indexOf(p) === -1
                      ) {
                        findProduct = false;
                        return;
                      }
                    });
                    if (!findProduct) {
                      return;
                    }
                  }
                } else {
                  if (
                    decodeURIComponent(param.Product)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .filter(
                        (item) =>
                          splitStringToArray(feature.Product).indexOf(item) !==
                          -1
                      ).length === 0
                  ) {
                    return false;
                  }
                }
              }

              if (
                param.ProductAreas &&
                feature.ModernWorkFeatureProductAreaId === null
              ) {
                return true;
              }

              if (
                param.ProductAreas &&
                feature.ModernWorkFeatureProductAreaId !== null
              ) {
                if (
                  !decodeURIComponent(param.ProductAreas)
                    .split(";|;")
                    .filter((item) => item !== "")
                    .includes(feature.ModernWorkFeatureProductAreaId.toString())
                ) {
                  return true;
                }
              }

              if (param.RoadmapID) {
                if (decodeURIComponent(param.RoadmapID) !== feature.RoadmapId) {
                  return true;
                }
              }

              if (param.SourceADOID) {
                if (
                  decodeURIComponent(param.SourceADOID) !== feature.SourceADOID
                ) {
                  return true;
                }
              }

              if (param.CloudInstance) {
                if (
                  !feature.CloudInstance ||
                  feature.CloudInstance.trim().length == 0
                ) {
                  if (
                    decodeURIComponent(param.CloudInstance)
                      .split(";|;")
                      .filter((item) => item.toLowerCase() === "none")
                      .length === 0
                  ) {
                    return true;
                  }
                } else {
                  if (
                    decodeURIComponent(param.CloudInstance)
                      .split(";|;")
                      .filter((item) => item.toLowerCase() !== "").length > 0
                  ) {
                    if (
                      decodeURIComponent(param.CloudInstance)
                        .split(";|;")
                        .filter((item) => item.toLowerCase() !== "")
                        .filter(
                          (item) =>
                            splitStringToArray(feature.CloudInstance).indexOf(
                              item
                            ) !== -1
                        ).length === 0
                    ) {
                      return true;
                    }
                  } else {
                    return true;
                  }
                }
              }

              if (param.PublicRoadmapStatus) {
                if (
                  decodeURIComponent(param.PublicRoadmapStatus)
                    .split(";|;")
                    .filter((item) => item !== "")
                    .filter(
                      (item) =>
                        splitStringToArray(feature.PublicRoadmapStatus).indexOf(
                          item
                        ) !== -1
                    ).length === 0
                ) {
                  return true;
                }
              }

              if (param.TierId) {
                if (
                  feature.TierId === undefined ||
                  feature.TierId === null ||
                  decodeURIComponent(param.TierId)
                    .split(";|;")
                    .filter((item) => item !== "")
                    .indexOf(feature.TierId.toString()) === -1
                ) {
                  return true;
                }
              }

              if (param.MomentId) {
                if (
                  (feature.ModernWorkSecurityEventCalendarMomentsId ===
                    undefined ||
                    feature.ModernWorkSecurityEventCalendarMomentsId === null ||
                    decodeURIComponent(param.MomentId)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .indexOf(
                        feature.ModernWorkSecurityEventCalendarMomentsId.toString()
                      ) === -1) &&
                  (feature.SecondModernWorkSecurityEventCalendarMomentsId ===
                    undefined ||
                    feature.SecondModernWorkSecurityEventCalendarMomentsId ===
                      null ||
                    decodeURIComponent(param.MomentId)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .indexOf(
                        feature.SecondModernWorkSecurityEventCalendarMomentsId.toString()
                      ) === -1)
                ) {
                  return true;
                }
              }

              if (param.TriageTagId) {
                if (
                  feature.TriageTagIds === undefined ||
                  feature.TriageTagIds === null ||
                  feature.TriageTagIds.length === 0 ||
                  decodeURIComponent(param.TriageTagId)
                    .split(";|;")
                    .filter((item) => item !== "")
                    .filter(
                      (v) => feature?.TriageTagIds!.indexOf(parseInt(v)) !== -1
                    ).length === 0
                ) {
                  return true;
                }
              }

              if (param.Planning) {
                const Planning =
                  feature.TierId !== null && feature.TierId !== undefined
                    ? "4"
                    : "5";
                if (
                  decodeURIComponent(param.Planning)
                    .split(";|;")
                    .filter((item) => item !== "")
                    .indexOf(Planning) === -1
                ) {
                  return true;
                }
              }

              if (
                param.OnlyShowWithNewToRoadmap &&
                !isVoid(param.OnlyShowWithNewToRoadmap) &&
                param.OnlyShowWithNewToRoadmap.toLowerCase() === "true"
              ) {
                if (
                  Math.abs(
                    moment(new Date()).diff(moment(feature.Created)) /
                      1000 /
                      3600 /
                      24 /
                      30
                  ) > 1
                ) {
                  return true;
                }
              }

              features.push(feature);
            });
            if (param.SortByDate) {
              const sortCondition = decodeURIComponent(param.SortByDate)
                .split(";|;")
                .filter((item) => item !== "");
              sortCondition.forEach((item) => {
                switch (item) {
                  case "PreviewDate":
                    features.sort((a, b) => {
                      return moment(a.PreviewDate) > moment(b.PreviewDate)
                        ? -1
                        : moment(a.PreviewDate) < moment(b.PreviewDate)
                        ? 1
                        : 0;
                    });
                    break;
                  case "GADate":
                    features.sort((a, b) => {
                      return moment(a.GADate) > moment(b.GADate)
                        ? -1
                        : moment(a.GADate) < moment(b.GADate)
                        ? 1
                        : 0;
                    });
                    break;
                  case "DisclosureDate":
                    features.sort((a, b) => {
                      return moment(a.DisclosureDate) > moment(b.DisclosureDate)
                        ? -1
                        : moment(a.DisclosureDate) < moment(b.DisclosureDate)
                        ? 1
                        : 0;
                    });
                    break;
                  default:
                    break;
                }
              });
            }

            if (features.length > 0) {
              searchResult += features.length;
              filterFeatures.push({
                productName: feature.productName,
                feature: features,
                featureExcelDownload: [initialModernWorkFeatureState],
              });
            }
          }
        );
      }

      let featureGroup = {
        month: dataSet.month,
        features: filterFeatures,
      };
      datas.push({
        month: dataSet.month,
        moments: { month: "", moment: [] },
        feature: featureGroup,
        isLoading: dataSet.isLoading,
      });
    });

    if (modernWorkFeatureModalContext.timelineMode == TimelineMode.Search) {
      if (searchResult === 0) {
        resultText = "no result available";
      } else if (searchResult === 1) {
        resultText = "1 result available";
      } else resultText = searchResult + " results available";

      document.body.querySelector("#ariaLiveReader")!.innerHTML = resultText;
    }

    return datas;
  }, [props.dataSet, param]);

  const featureDom = featureDataSets.map((item, index) => {
    return item.month !== "" ? (
      <div
        data-featurenexmonth={item.month}
        className={`grid-item grid-item-milestone ${
          index !== 0 ? "milestone-border-left" : ""
        }`}
        key={item.month + "_milestone"}
      >
        {item.isLoading === _LOADINGSTATE.fullfilled ? (
          <ProductFeature productFeatures={item.feature} />
        ) : (
          <Spinner
            size={SpinnerSize.large}
            label="Loading content"
            ariaLive="polite"
            labelPosition="top"
          />
        )}
      </div>
    ) : null;
  });

  return <>{featureDom}</>;
};

export default RenderFeature;
