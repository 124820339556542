export const msalConfig = {
  auth: {
    authority: "https://login.microsoftonline.com/organizations",
    clientId: "6708cd51-d707-4bd2-91a0-f6164314ecbf",
    redirectUri: window.location.href
      .replace(window.location.search, "")
      .replace(/#!$/, "")
      .toLowerCase(),
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
