import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
  convertMonthStringToDate,
  getFirstDayInTheMonth,
  getLastDayInTheMonth,
  getMonthList,
} from "../utils/date";
import { isVoid } from "../utils/utils";
import { _LOADINGSTATE } from "../components/Shared/constants";
import timelineService from "../services/timeline";
import azureTimelineService from "../services/azureTimeline";
import milestoneService from "../services/milestone";
import modernWorkSecurityEventCalendarMomentService from "../services/modernWorkSecurityEventCalendarMoment";
import { Milestone } from "../slice/milestone";
import { ErrorMessage } from "../components/Shared/messageBox";
import { MomentsGroupByProps, timelineTrainDates } from "./timeline";
import { Conversation } from "./conversation";
import { Product } from "./product";
import { modernWorkSecurityEventCalendarMomentOption } from "./modernWorkSecurityEventCalendarMoment";

export interface milestoneTier {
  TierId: number;
  Name: string;
}

export interface milestoneType {
  MilestoneTypeId: number;
  Name: string;
}

export interface AzureTimelineFilter {
  Conversations: Conversation[];
  MilestoneCreators: string[];
  MilestoneLastUpdateBy: string[];
  ProductIdAndNames: Product[];
  MilestoneTiers: milestoneTier[];
  MilestoneTypes: milestoneType[];
  Moments: modernWorkSecurityEventCalendarMomentOption[];
}

const azureTimelineFilterOptionInit: AzureTimelineFilter = {
  Conversations: [],
  MilestoneCreators: [],
  MilestoneLastUpdateBy: [],
  ProductIdAndNames: [],
  MilestoneTiers: [],
  MilestoneTypes: [],
  Moments: [],
};

export interface azureTimelineMilestone {
  MilestoneId: number;
  MilestoneName: string;
  TierId: number;
  Tier: string;
  RoadmapStartDate?: string;
  MilestoneTypeId: number;
  MilestoneTypeName: string;
  ContactName: string;
  MarketingLeadName: string;
  CreatedBy: string;
  ModifiedBy: string;
  RoadmapMilestoneId?: number;
  DisclosureMilestoneId?: number;
  Status: number;
  ModernWorkSecurityEventCalendarMomentsId: number | null;
  SecondModernWorkSecurityEventCalendarMomentsId: number | null;
  MilestoneDescription: string;
  EngineeringContactName?: string;
  Audiences: string;
  DisclosureStartDate?: string;
  AlignedMomentName?: string;
  IsNotShare: boolean;
  CreatedDate: string;
}

export interface milestoneGroupByProps {
  month: string;
  milestones: {
    productName: string;
    conversationsId: string;
    conversationsName: string;
    milestone: azureTimelineMilestone[];
    milestoneExcelDownload: Milestone[];
  }[];
}

export interface DataSetProps {
  month: string;
  moments: MomentsGroupByProps;
  milestone: milestoneGroupByProps;
  isLoading: string;
}

export const initDataSetState: DataSetProps[] = [];

interface initialSateProps {
  dataSet: DataSetProps[];
  filterDataSet: DataSetProps[];
  excelDownloadDataSet: {
    conversationId: string;
    conversationName: string;
    categoryName: string;
    productName: string;
    productState: number;
    milestoneExcelDownload: Milestone[];
  }[];
  isMonthLoading: string;
  isLoadingSearchResult: boolean;
  isLoadingExportExcelMilestone: boolean;
  isLoadedExportExcelMilestone: boolean;
  isExportingExcelMilestone: boolean;
  azureTimelineFilterOption: AzureTimelineFilter;
  isFilterLoading: string;
}

const initialState: initialSateProps = {
  dataSet: initDataSetState,
  filterDataSet: initDataSetState,
  excelDownloadDataSet: [],
  isMonthLoading: _LOADINGSTATE.pending,
  isLoadingSearchResult: false,
  isLoadingExportExcelMilestone: false,
  isLoadedExportExcelMilestone: false,
  isExportingExcelMilestone: false,
  azureTimelineFilterOption: azureTimelineFilterOptionInit,
  isFilterLoading: _LOADINGSTATE.pending,
};

export const initAzureTimelineDataSet = createAsyncThunk(
  "initAzureTimelineDataSet",
  async () =>
    (await Promise.all([
      milestoneService.getMilestoneMonths(),
      modernWorkSecurityEventCalendarMomentService.getModernWorkSecurityEventCalendarMomentMonths(
        "Azure"
      ),
    ]).then((monthResponse) => {
      const milestoneMonths = monthResponse[0] as string[];
      const modernWorkSecurityEventCalendarMomentMonths =
        monthResponse[1] as string[];
      const months = Array.from(
        new Set(
          milestoneMonths.concat(modernWorkSecurityEventCalendarMomentMonths)
        )
      );
      months.sort();
      return months;
    })) as string[]
);

export const getDataSet = (startDate?: Date, endDate?: Date) => {
  return async (dispatch: any) => {
    dispatch(
      azureTimelineSlice.actions.setDataSetIsLoading({
        startDate: getFirstDayInTheMonth(
          moment(startDate).format("MM/DD/YYYY")
        ),
        endDate: getLastDayInTheMonth(moment(endDate).format("MM/DD/YYYY")),
      })
    );
    await Promise.all([
      timelineService.getMoments(
        startDate === undefined ? "" : moment(startDate).format("MM/DD/YYYY"),
        endDate === undefined ? "" : moment(endDate).format("MM/DD/YYYY"),
        "Azure"
      ),
      azureTimelineService.getTimelineMilestone(
        startDate === undefined ? "" : moment(startDate).format("MM/DD/YYYY"),
        endDate === undefined ? "" : moment(endDate).format("MM/DD/YYYY")
      ),
    ])
      .then((response) => {
        const momenstListPayload = response[0] as MomentsGroupByProps[];
        const milestoneListPayload = response[1] as milestoneGroupByProps[];
        dispatch(
          azureTimelineSlice.actions.setDataSet({
            moment: momenstListPayload,
            milestone: milestoneListPayload,
          })
        );
        dispatch(
          azureTimelineSlice.actions.setDataSetIsLoaded({
            startDate: getFirstDayInTheMonth(
              moment(startDate).format("MM/DD/YYYY")
            ),
            endDate: getLastDayInTheMonth(moment(endDate).format("MM/DD/YYYY")),
          })
        );
      })
      .catch((error: any) => {
        ErrorMessage.show(
          "There was an error getting milestones. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      });
  };
};

export const getMilestoneDataSet = (startDate?: Date, endDate?: Date) => {
  return async (dispatch: any) => {
    azureTimelineService
      .getTimelineMilestone(
        startDate === undefined ? "" : moment(startDate).format("MM/DD/YYYY"),
        endDate === undefined ? "" : moment(endDate).format("MM/DD/YYYY")
      )
      .then((response) => {
        const milestoneListPayload = response as milestoneGroupByProps[];
        dispatch(
          azureTimelineSlice.actions.setMilestoneDataSet({
            milestone: milestoneListPayload,
          })
        );
      })
      .catch((error: any) => {
        ErrorMessage.show(
          "There was an error getting milestones. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      });
  };
};

export const getExcelDownloadMilestoneDataSet = (
  startDate?: Date,
  endDate?: Date
) => {
  return async (dispatch: any) => {
    const excelDownloadMilestoneListPayload = (await azureTimelineService
      .getTimelineExcelDownloadMilestone(
        startDate === undefined ? "" : moment(startDate).format("MM/DD/YYYY"),
        endDate === undefined ? "" : moment(endDate).format("MM/DD/YYYY")
      )
      .catch((error: any) => {
        ErrorMessage.show(
          "There was an error download milestones. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      })) as {
      conversationId: string;
      conversationName: string;
      categoryName: string;
      productName: string;
      productState: number;
      milestoneExcelDownload: Milestone[];
    }[];

    dispatch(
      azureTimelineSlice.actions.setExcelDownloadMilestoneDataSet({
        milestone: excelDownloadMilestoneListPayload,
      })
    );
  };
};

export const getAzureTimelineFilters = createAsyncThunk(
  "getAzureTimelineFilters",
  async () => (await azureTimelineService.getFilters()) as AzureTimelineFilter
);

export const getSearchDataSet = (searchPhrase?: string) => {
  return async (dispatch: any) => {
    dispatch(azureTimelineSlice.actions.setIsLoadingSearchResult(true));

    if (
      searchPhrase !== undefined &&
      searchPhrase !== null &&
      searchPhrase.trim() !== ""
    ) {
      azureTimelineService
        .getTimelineMilestoneSearch(searchPhrase)
        .then((response) => {
          const milestoneListPayload = response as milestoneGroupByProps[];
          //let searchResultDiv = document.querySelector("#searchResult");

          if (milestoneListPayload.length > 0) {
            //searchResultDiv!.innerHTML =
            //  milestoneListPayload[0].milestones.length + " results available";
            const months = milestoneListPayload.map((x) => x.month);

            dispatch(azureTimelineSlice.actions.setMonth({ dateList: months }));
            dispatch(
              azureTimelineSlice.actions.setDataSet({
                moment: [],
                milestone: milestoneListPayload,
              })
            );

            let date = milestoneListPayload[0].month;

            dispatch(
              azureTimelineSlice.actions.setDataSetIsLoaded({
                startDate: moment(date).toDate(),
                endDate: moment(date).toDate(),
              })
            );
          } else {
            dispatch(azureTimelineSlice.actions.setMonth({ dateList: [] }));
            dispatch(
              azureTimelineSlice.actions.setDataSet({
                moment: [],
                milestone: [],
              })
            );
            //searchResultDiv!.innerHTML = "no results available";
          }
          dispatch(azureTimelineSlice.actions.setIsLoadingSearchResult(false));
        });
    }
  };
};

export const getTimelineExcelDownloadSearchMilestone = (
  searchPhrase?: string
) => {
  return async (dispatch: any) => {
    const excelDownloadMilestoneListPayload =
      (await azureTimelineService.getTimelineExcelDownloadSearchMilestone(
        searchPhrase
      )) as {
        conversationId: string;
        conversationName: string;
        categoryName: string;
        productName: string;
        productState: number;
        productStateName: string;
        milestoneExcelDownload: Milestone[];
      }[];

    dispatch(
      azureTimelineSlice.actions.setExcelDownloadMilestoneDataSet({
        milestone: excelDownloadMilestoneListPayload,
      })
    );
  };
};

const azureTimelineSlice = createSlice({
  name: "azureTimeline",
  initialState: initialState,
  reducers: {
    setMonth: (
      state: initialSateProps,
      action: {
        payload: { dateList: string[] };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = [];
      action.payload.dateList.map((item, index) => {
        const data = {
          month: item,
          moments: { month: item, moment: [] },
          milestone: { month: item, milestones: [] },
          isLoading: "",
        };
        dataSet.push(data);
      });
      state.dataSet = dataSet;
    },
    setDataSet: (
      state: initialSateProps,
      action: {
        payload: {
          moment: MomentsGroupByProps[];
          milestone: milestoneGroupByProps[];
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;

      dataSet.forEach((ele) => {
        const currentMoment = action.payload.moment.find(
          (m) => m.month === ele.month
        );
        if (!isVoid(currentMoment)) {
          ele.moments = currentMoment as MomentsGroupByProps;
        }

        const currentMilestone = action.payload.milestone.find(
          (m) => m.month === ele.month
        );
        if (!isVoid(currentMilestone)) {
          ele.milestone = currentMilestone as milestoneGroupByProps;
        }
      });

      state.dataSet = dataSet;
    },
    setDataSetIsLoading: (
      state: initialSateProps,
      action: {
        payload: {
          startDate: Date;
          endDate: Date;
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;
      dataSet.forEach((ele) => {
        if (
          moment(convertMonthStringToDate(ele.month)).utc() >=
            moment(action.payload.startDate).utc() &&
          moment(convertMonthStringToDate(ele.month)).utc() <=
            moment(action.payload.endDate).utc()
        ) {
          ele.isLoading = _LOADINGSTATE.pending;
        }
      });
      state.dataSet = dataSet;
    },
    setDataSetIsLoaded: (
      state: initialSateProps,
      action: {
        payload: {
          startDate: Date;
          endDate: Date;
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;
      dataSet.forEach((ele) => {
        if (
          moment(convertMonthStringToDate(ele.month)).utc() >=
            moment(action.payload.startDate).utc() &&
          moment(convertMonthStringToDate(ele.month)).utc() <=
            moment(action.payload.endDate).utc()
        ) {
          ele.isLoading = _LOADINGSTATE.fullfilled;
        }
      });
      state.dataSet = dataSet;
    },
    setMilestoneDataSet: (
      state: initialSateProps,
      action: {
        payload: {
          milestone: milestoneGroupByProps[];
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;

      dataSet.forEach((ele) => {
        const currentMilestone = action.payload.milestone.find(
          (m) => m.month === ele.month
        );
        if (!isVoid(currentMilestone)) {
          ele.milestone = currentMilestone as milestoneGroupByProps;
        }
      });

      state.dataSet = dataSet;
    },
    setExcelDownloadMilestoneDataSet: (
      state: initialSateProps,
      action: {
        payload: {
          milestone: {
            conversationId: string;
            conversationName: string;
            categoryName: string;
            productName: string;
            productState: number;
            milestoneExcelDownload: Milestone[];
          }[];
        };
        type: string;
      }
    ) => {
      state.excelDownloadDataSet = action.payload.milestone;
      state.isLoadingExportExcelMilestone = false;
    },
    setFilterDataSet: (
      state: initialSateProps,
      action: { payload: DataSetProps[]; type: string }
    ) => {
      state.filterDataSet = action.payload;
    },
    setIsLoadingSearchResult: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isLoadingSearchResult = action.payload;
    },
    setIsLoadingExportExcelMilestone: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isLoadingExportExcelMilestone = action.payload;
    },
    setIsLoadedExportExcelMilestone: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isLoadedExportExcelMilestone = action.payload;
    },
    setIsExportingExcelMilestone: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isExportingExcelMilestone = action.payload;
    },
    setTrainDataSet: (
      state: initialSateProps,
      action: { payload: { startDate: Date; endDate: Date }; type: string }
    ) => {
      const dates = getMonthList(
        action.payload.startDate,
        action.payload.endDate
      );
      let dataSet: DataSetProps[] = [];
      dates.map((item, index) => {
        const data = {
          month: item,
          moments: { month: item, moment: [] },
          milestone: { month: item, milestones: [] },
          isLoading: "",
        };
        dataSet.push(data);
      });
      state.dataSet = dataSet;
    },
  },
  extraReducers: {
    [initAzureTimelineDataSet.fulfilled.type]: (
      state: initialSateProps,
      action: { payload: string[]; type: string }
    ) => {
      let dataSet: DataSetProps[] = [];
      action.payload.map((item, index) => {
        const data = {
          month: item,
          moments: { month: item, moment: [] },
          milestone: { month: item, milestones: [] },
          isLoading: "",
        };
        dataSet.push(data);
      });
      state.dataSet = dataSet;
      state.isMonthLoading = _LOADINGSTATE.fullfilled;
    },
    [initAzureTimelineDataSet.rejected.type]: (state: initialSateProps) => {
      ErrorMessage.show(
        "There was an error getting timeline months. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [initAzureTimelineDataSet.pending.type]: (state: initialSateProps) => {
      state.isMonthLoading = _LOADINGSTATE.pending;
    },
    [getAzureTimelineFilters.fulfilled.type]: (
      state: initialSateProps,
      action: { payload: AzureTimelineFilter; type: string }
    ) => {
      state.azureTimelineFilterOption = action.payload;
      state.isFilterLoading = _LOADINGSTATE.fullfilled;
    },
    [getAzureTimelineFilters.rejected.type]: (state: initialSateProps) => {
      ErrorMessage.show(
        "There was an error getting timeline options. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAzureTimelineFilters.pending.type]: (state: initialSateProps) => {
      state.isFilterLoading = _LOADINGSTATE.pending;
    },
  },
});

export const { reducer, actions } = azureTimelineSlice;
